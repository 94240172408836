import React from 'react';

import "../components/WelcomeSection.css"
import growniverseImage from "../Photos/Logo/growniverse-appicon-transparent.svg";
import { MoonLoader } from 'react-spinners';
const UserLoader = () => {
    return (
        <>
            <div
                style={{
                    position: "absolute",
                    background: "white",
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <img
                    src={growniverseImage}
                    alt="Loading..."
                    width="100px" // Adjust size as needed
                    style={{
                        position: "absolute",
                        animation: "spin 5s linear infinite", // Apply the spin animation
                    }}
                />
                <MoonLoader size="120" color="#f5b45f" />
            </div>
        </>
    );
};

export default UserLoader;
