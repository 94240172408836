import React, { useState, useEffect, useRef } from 'react';

interface InnerBannerProps {
  statistics: {
    students: number;
    courses: number;
    teachers: number;
    awards: number;
  };
}

const StatisticsSection: React.FC<InnerBannerProps> = ({ statistics }) => {
  const [counters, setCounters] = useState({
    students: 0,
    courses: 0,
    teachers: 0,
    awards: 0,
  });

  const animatedCounters = useRef({
    students: Math.random() * statistics.students, // Start from a random value
    courses: Math.random() * statistics.courses,
    teachers: Math.random() * statistics.teachers,
    awards: Math.random() * statistics.awards
  });

  const animationRef = useRef<number | null>(null);

  useEffect(() => {
    const targetValues = statistics;
    const duration = 2000; // Animation duration in milliseconds
    const startTime = performance.now();

    const animateCounters = (currentTime: number) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);
      const newValues = {
        students: animatedCounters.current.students + progress * (targetValues.students - animatedCounters.current.students),
        courses: animatedCounters.current.courses + progress * (targetValues.courses - animatedCounters.current.courses),
        teachers: animatedCounters.current.teachers + progress * (targetValues.teachers - animatedCounters.current.teachers),
        awards: animatedCounters.current.awards + progress * (targetValues.awards - animatedCounters.current.awards),
      };
      setCounters({
        students: Math.round(newValues.students),
        courses: Math.round(newValues.courses),
        teachers: Math.round(newValues.teachers),
        awards: Math.round(newValues.awards),
      });
      if (progress < 1) {
        animationRef.current = requestAnimationFrame(animateCounters);
      }
    };

    animationRef.current = requestAnimationFrame(animateCounters);
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [statistics]);
  return (
    <section className="statistics bg-primary py-4">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-lg-3 d-flex mb-4 mb-lg-0 d-flex align-items-center">
            <i className="flaticon-user text-white fa-4x me-3"></i>
            <div className="counter">
              <div className="counter-number">
                <h3 className="timer text-white mb-0">{counters.students}</h3>
              </div>
              <p className="mb-0 fw-6 text-white">Happy Students</p>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 d-flex mb-4 mb-lg-0 d-flex align-items-center">
            <i className="flaticon-list-1 text-white fa-4x me-3"></i>
            <div className="counter">
              <div className="counter-number">
                <h3 className="timer text-white mb-0">{counters.courses}</h3>
              </div>
              <p className="mb-0 fw-6 text-white">Our Courses</p>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 d-flex mb-4 mb-sm-0 d-flex align-items-center">
            <i className="flaticon-studying text-white fa-4x me-3"></i>
            <div className="counter">
              <div className="counter-number">
                <h3 className="timer text-white mb-0">{counters.teachers}</h3>
              </div>
              <p className="mb-0 fw-6 text-white">Our Teachers</p>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 d-flex">
            <i className="flaticon-guarantee text-white fa-4x me-3 d-flex align-items-center"></i>
            <div className="counter">
              <div className="counter-number">
                <h3 className="timer text-white mb-0">{counters.awards}</h3>
              </div>
              <p className="mb-0 fw-6 text-white">Awards Won</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StatisticsSection;
