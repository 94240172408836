import "../assets/css/package.css"
import basic from "../Photos/Package/Basic_Package.png"
import advanced from "../Photos/Package/Advanced_Package.png"
import silver from "../Photos/Package/Silver_Package.png"
import Gold from "../Photos/Package/Gold_Package.png"
import Platinum from "../Photos/Package/Platinum_Package.png"
import spaceBaground from "../Photos/Background/spacebg.jpg";
import { Button, Link } from "@mui/material";
import { useEffect, useRef, useState } from "react"
import { url } from "inspector"

const Package = () => {
    const [packages, setPackages] = useState([
        {
            "packageId": 0,
            "packageName": "",
            "packageDescription": "",
            "postarLink": basic,
            "packagePrice": 12000,
            "discountedPrice": 8500,
            "price": 6400
        }
    ]);
    const runOnce = useRef(false);

    const packageDetails = (pack: any) => {
        if (pack) {
            localStorage.setItem("packageDetail", JSON.stringify(pack));
            window.location.href = "/packageDetail"
        }
    };

    useEffect(() => {
        if (runOnce.current === false) {
            const storedProfile = localStorage.getItem("userProfile");
            const userProfile = JSON.parse(storedProfile ?? "");
            if(userProfile.status>3){
                window.location.href = "/dashboard";
            }
            var storedPackages = localStorage.getItem("packages");
            var packagesData = JSON.parse(storedPackages ?? "");
            setPackages(packagesData);
            runOnce.current = true;
        }
    }, [runOnce])
    return <>
        <section style={{ background: "#343434", padding: "20px" }}>
            <div className="container" style={{ justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                <div className="row">
                    {packages.map((pack) =>
                        <div className="col-md-4 col-sm-6 headerBackground" style={{ borderRadius: "20px", padding: "10px" }}>
                            <div className="mb-md-0" style={{ height: "auto" }}>
                                {pack.packageId === 1 ?
                                    <img className="img-fluid" src={basic} alt={basic} style={{ width: "100%", height: "400px", borderRadius: "20px" }} />
                                    : pack.packageId === 2 ?
                                        <img className="img-fluid" src={advanced} alt={advanced} style={{ width: "100%", height: "400px", borderRadius: "20px" }} />
                                        : pack.packageId === 3 ?
                                            <img className="img-fluid" src={silver} alt={silver} style={{ width: "100%", height: "400px", borderRadius: "20px" }} />
                                            : pack.packageId === 4 ?
                                                <img className="img-fluid" src={Gold} alt={Gold} style={{ width: "100%", height: "400px", borderRadius: "20px" }} />
                                                : pack.packageId === 5 ?
                                                    <img className="img-fluid" src={Platinum} alt={Platinum} style={{ width: "100%", height: "400px", borderRadius: "20px" }} />
                                                    : <></>
                                }
                            </div><br />
                            <div style={{ color: "black", background: "#899ba1", width: "100%", display: "inline-grid", borderRadius: "50px" }}>
                                <h2 style={{ color: "#Daa425", textShadow: "0 0 3px #FF0000, 0 0 5px #0000FF" }}>
                                    {pack.packageName}
                                </h2>
                            </div><br /><br />
                            <div style={{ alignContent: "center", color: "black", background: "#919191", width: "100%", height: "100px", display: "inline-grid", borderRadius: "10px" }}>
                                <p style={{ display: "flex", justifyContent: "space-between", padding: "0 40px 0 40px" }}>
                                    <span style={{ fontSize: "20px" }}>
                                        <span style={{ color: "black", textDecorationLine: "line-through" }}>
                                            <span>₹{pack.packagePrice}</span>
                                        </span>
                                    </span>
                                    <span style={{ fontSize: "25px" }}>
                                        <span style={{ color: "black", textDecorationLine: "line-through" }}>
                                            <span>₹{pack.discountedPrice}</span>
                                        </span>
                                    </span>
                                    <span style={{ fontSize: "30px" }}>
                                        <span style={{ color: "red" }}>
                                            <span>₹{pack.price}</span>
                                        </span>
                                    </span>
                                </p>
                                <div>
                                    <button style={{ borderRadius: "30px", background: "#343434" }} onClick={() => packageDetails(pack)}>
                                        <b style={{ color: "#Daa425" }}>EXPLORE MORE</b>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    </>
};

export default Package;