import { TableCell, TableContainer, TableHead, Button, Table, TableBody, TablePagination, TableRow, TextField, Input, colors, Alert } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import UserLoader from '../Dashboard/UserLoader';
import { sendKycForApproval } from '../handler/LoginAndSingupHandler';
import { getAccountDetails } from '../handler/accountHandler';
const KycUserPannel = () => {

    const userProfileData = {
        appToken: "",
        userInformationId: 0,
        fullName: "Robin Sharma",
        sponsorGrowniverseId: "string",
        growniverseId: "G10025",
        address: "string",
        country: "India",
        state: null,
        pincode: 0,
        userIdentityGuid: "ef035009-7095-4a70-8882-63a86f062681-5bd0bcdb-a6d8-47a5-9c81-708f75c0b95f",
        emailID: "string",
        phoneNumber: "string",
        emailConfirmation: true,
        packageId: 0,
        packageName: "",
        status: 1
    };
    const [userProfile, setUserProfile] = useState(userProfileData);

    const [bankVerificaion, setBankVerification] = useState(null);
    const [adharCardImage, setAdharCardImage] = useState(null);
    const [adharCardImageBack, setAdharCardImageBack] = useState(null);
    const [panCardImage, setPanCardImage] = useState(null);
    const handleBankVerificaionChange = (event: any) => {
        setBankVerification(event.target.files[0]);
    };
    const handleAdharCardChange = (event: any) => {
        setAdharCardImage(event.target.files[0]);
    };
    const handleAdharCardBackChange = (event: any) => {
        setAdharCardImageBack(event.target.files[0]);
    };
    const handlePanCardChange = (event: any) => {
        setPanCardImage(event.target.files[0]);
    };

    const sendKyc = async (event: any) => {
        event.preventDefault();
        setLoader(true);
        const formData = new FormData();
        if (!bankVerificaion) {
            alert('Please select a file Bank File before submitting.');
            setLoader(false);
            return;
        }
        if (!adharCardImage) {
            alert('Please select a Adhar Card file before submitting.');
            setLoader(false);
            return;
        }
        if (!adharCardImageBack) {
            alert('Please select a Adhar Card file before submitting.');
            setLoader(false);
            return;
        }
        if (!panCardImage) {
            alert('Please select a Pan Card file before submitting.');
            setLoader(false);
            return;
        }
        const formDetail = event.target;
        formData.append('UserInformationId', userProfile.userInformationId.toString());
        formData.append("BankName", formDetail[0].value);
        formData.append("IFSCCode", formDetail[1].value);
        formData.append("AccountName", formDetail[2].value);
        formData.append("AccountNumber", formDetail[3].value);
        formData.append('BankImage', bankVerificaion);
        formData.append("UPIId", formDetail[5].value);
        formData.append("UPIName", formDetail[6].value);
        formData.append("AdharCard", formDetail[7].value);
        formData.append('AdharCardImage', adharCardImage);
        formData.append('AdharCardImageBack', adharCardImageBack);
        formData.append("PanCard", formDetail[10].value);
        formData.append('PanCardImage', panCardImage);
        await sendKycForApproval(formData);
        window.location.reload();
    }
    const [loader, setLoader] = useState(false);

    const [kycActive, setKycActive] = useState({
        bankName: "",
        accountName: "",
        accountNumber: 0,
        ifscCode: "",
        upiId: "",
        upiName: "",
        adharCard: 0,
        panCard: ""
    });

    const getUserProfileData = async () => {
        const storedProfile = localStorage.getItem("userProfile");
        const userProfile = JSON.parse(storedProfile ?? "");
        setUserProfile(userProfile);
        return userProfile.userInformationId;
    }

    const getActiveKyc = async () => {
        setLoader(true);

        const userInformationId = await getUserProfileData();
        var response = await getAccountDetails(userInformationId, 2);
        if (response.statusCode === 200) {
            var ch = response.data.account.kycDetail;
            setKycActive(response.data.account.kycDetail);
        }
        setLoader(false);
    };

    const runOnce = useRef(false);
    useEffect(() => {
        if (runOnce.current === false) {
            getActiveKyc();
            runOnce.current = true;
        }
    }, [runOnce]);
    return (
        <>
            <section className='headerBackground'>
                {
                    loader ? (
                        <UserLoader />
                    ) :
                        <div className='space-ptb achievers-section headerBackground' style={{ borderStyle: "inset", borderColor: "white", padding: "30px" }}>
                            <div style={{ background: "#343434" }}>
                                <h3 style={{ textAlign: "center", color: "white" }}>My KYC</h3>
                                <h6 style={{ textAlign: "center", color: "white" }}><span style={userProfile.status <= 4 ? { color: "red" } : { color: "Yellow" }}>{userProfile.status <= 4 ? "Pending" : userProfile.status === 5 ? "Requested" : ""}</span></h6>
                                {
                                    userProfile.status === 6 && (
                                        <h6 style={{ textAlign: "center", color: "white" }}><span style={{ color: "green" }}>Active</span></h6>
                                    )
                                }
                            </div>
                            {
                                kycActive && (
                                    <TableContainer style={{ background: "white", padding: "20px" }}><br /><br />
                                        <div style={{ background: "black" }}>
                                            <h6 style={{ textAlign: "center", color: "white" }}>Bank Details</h6>
                                        </div>
                                        <Table>
                                            <TableHead>
                                                <TableRow >
                                                    <TableCell align="center">Bank Name</TableCell>
                                                    <TableCell align="center">{kycActive.bankName}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="center">IFSCCode</TableCell>
                                                    <TableCell align="center">{kycActive.ifscCode}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="center">Account Holder Name</TableCell>
                                                    <TableCell align="center">{kycActive.accountName}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="center">Account Number</TableCell>
                                                    <TableCell align="center">{kycActive.accountNumber}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table><br /><br />
                                        <div style={{ background: "black" }}>
                                            <h6 style={{ textAlign: "center", color: "white" }}>UPI Details</h6>
                                        </div>
                                        <Table>
                                            <TableHead>
                                                <TableRow >
                                                    <TableCell align="center">UPI ID</TableCell>
                                                    <TableCell align="center">{kycActive.upiId}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableHead>
                                                <TableRow >
                                                    <TableCell align="center">UPI Name</TableCell>
                                                    <TableCell align="center">{kycActive.upiName}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                        </Table><br /><br />
                                        <div style={{ background: "black" }}>
                                            <h6 style={{ textAlign: "center", color: "white" }}>Personal Details</h6>
                                        </div>
                                        <Table>
                                            <TableHead>
                                                <TableRow >
                                                    <TableCell align="center">Adhar Card Number</TableCell>
                                                    <TableCell align="center">{kycActive.adharCard}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableHead>
                                                <TableRow >
                                                    <TableCell align="center">Pan Card Number</TableCell>
                                                    <TableCell align="center">{kycActive.panCard}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                        </Table>
                                    </TableContainer>
                                )
                            }
                            {
                                userProfile.status === 4 && (
                                    <>
                                        {
                                            loader && (
                                                <UserLoader />
                                            )
                                        }
                                        {
                                            !loader && (
                                                <>
                                                    <div style={{ padding: "20px 20px 0 20px", background: "white", justifyContent: "center", display: "flex" }}>
                                                        <Alert severity="info" variant="outlined">
                                                            Your Name Must Match With Your All Documents As On Growniverse ID
                                                        </Alert>
                                                    </div>
                                                    <form onSubmit={sendKyc}>
                                                        <TableContainer style={{ background: "white", padding: "0 20px 20px 20px" }}><br /><br />
                                                            <div style={{ background: "black" }}>
                                                                <h6 style={{ textAlign: "center", color: "white" }}>Bank Details</h6>
                                                            </div>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow >
                                                                        <TableCell align="center">Bank Name <span style={{ color: "red" }}>*</span></TableCell>
                                                                        <TableCell align="center"><Input type='text' style={{ color: "black" }} placeholder='Bank Name' required /></TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell align="center">IFSC Code <span style={{ color: "red" }}>*</span></TableCell>
                                                                        <TableCell align="center"><Input type='text' style={{ color: "black" }} placeholder='IFSC Code' required /></TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell align="center">Account Holder Name <span style={{ color: "red" }}>*</span></TableCell>
                                                                        <TableCell align="center"><Input type='text' style={{ color: "black" }} placeholder='Holder Name' required /></TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell align="center">Account Number <span style={{ color: "red" }}>*</span></TableCell>
                                                                        <TableCell align="center"><Input type='number' style={{ color: "black" }} placeholder='Account Number' required /></TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell align="center">Bank Passbook/<br />Cancel Cheque <span style={{ color: "red" }}>*</span></TableCell>
                                                                        <TableCell align="center"><Input name='imageFileData' type="file" onChange={handleBankVerificaionChange} required /></TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table><br /><br />
                                                            <div style={{ background: "black" }}>
                                                                <h6 style={{ textAlign: "center", color: "white" }}>UPI Details</h6>
                                                            </div>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow >
                                                                        <TableCell align="center">UPI Number<br /> or Id <span style={{ color: "red" }}>*</span></TableCell>
                                                                        <TableCell align="center"><Input type='text' style={{ color: "black" }} placeholder='UPI Number Or Id' required /></TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell align="center">UPI Name <span style={{ color: "red" }}>*</span></TableCell>
                                                                        <TableCell align="center"><Input type='text' style={{ color: "black" }} placeholder='UPI Name' required /></TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table><br /><br />
                                                            <div style={{ background: "black" }}>
                                                                <h6 style={{ textAlign: "center", color: "white" }}>Personal Details</h6>
                                                            </div>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow >
                                                                        <TableCell align="center">Adhar Card Number <span style={{ color: "red" }}>*</span></TableCell>
                                                                        <TableCell align="center"><Input type='number' style={{ color: "black" }} placeholder='Adhar Card Number' required /></TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell align="center">Adhar Card Front <span style={{ color: "red" }}>*</span></TableCell>
                                                                        <TableCell align="center"><Input name='imageFileData' type="file" onChange={handleAdharCardChange} required /></TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell align="center">Adhar Card Back <span style={{ color: "red" }}>*</span></TableCell>
                                                                        <TableCell align="center"><Input name='imageFileData' type="file" onChange={handleAdharCardBackChange} required /></TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                                <TableHead>
                                                                    <TableRow >
                                                                        <TableCell align="center">Pan Card Number <span style={{ color: "red" }}>*</span></TableCell>
                                                                        <TableCell align="center"><Input type='text' style={{ color: "black" }} placeholder='Pan Card Number' required /></TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell align="center">Pan Card Image <span style={{ color: "red" }}>*</span></TableCell>
                                                                        <TableCell align="center"><Input name='imageFileData' type="file" onChange={handlePanCardChange} required /></TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table><br /><br />
                                                            <div style={{ textAlign: 'center' }}>
                                                                <Button type='submit' style={{ background: "orange" }}>Request Kyc</Button>
                                                            </div>
                                                        </TableContainer>
                                                    </form>
                                                </>
                                            )
                                        }
                                    </>
                                )
                            }
                            {
                                userProfile.status === 5 && (<>
                                    <Alert severity="success" variant="outlined" style={{ background: "white" }}>
                                        Kyc Successfully Submitted
                                    </Alert>
                                </>)
                            }
                        </div>
                }
            </section>
        </>
    );
};

export default KycUserPannel;
