import { Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import UserLoader from "../UserLoader";
import { GetUserCourses } from "../../handler/coursehandler";

const MyCourses = () => {
    const [courses, setCourses] = useState([
        {
            courseId: 1,
            courseName: "",
            courseDescription: "",
            status: "",
            images: ""
        }
    ]); // State to hold the list of courses
    const [loader, setLoader] = useState(true); // State to manage loading status
    const runOnce = useRef(false); // Ref to ensure effect runs only once
    const userCourses = async () => {
        const storedProfile = localStorage.getItem("userProfile");
        const userProfile = JSON.parse(storedProfile ?? "");
        const response = await GetUserCourses(userProfile.packageId);
        if (response.statusCode === 200) {
            setCourses(response.data.courses);
        }
        setLoader(false);
    }
    useEffect(() => {
        // Run effect only once
        if (runOnce.current === false) {
            userCourses();
            runOnce.current = true;
        }
    }, []);

    return (
        <>
            <style>
                {`
                    .list-group-item {
                        transition: background-color 0.3s ease;
                    }

                    .list-group-item:hover {
                        background-color: #f1f1f1;
                    }
                    
                    .course-container {
                        border: 5px solid #343434;
                        padding: 20px;
                    }
                    
                    .course-header {
                        text-align: center;
                        color: white;
                    }

                    .badge {
                        background-color: #17a2b8;
                        color: white;
                        padding: 5px 10px;
                        border-radius: 20px;
                    }
                `}
            </style>
            <section style={{ padding: "20px" }} className="headerBackground">
                <h3 style={{ textAlign: "center", color: "white", background: "black" }}>My Courses</h3>
                {
                    loader && (
                        <>
                            <UserLoader />
                        </>
                    )
                }
                {
                    !loader && (
                        <>
                            <div className="course-container" style={{ background: "#F6F6F0" }}>
                                {(
                                    <ul className="list-group" style={{ gap: "20px" }}>
                                        {courses.map((course) => (
                                            <>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        height: "auto",
                                                        padding: "0",
                                                        position: "relative",
                                                        display: "block",
                                                    }}
                                                >
                                                    <div
                                                        className="categories"
                                                    >
                                                        <img className="img-fluid" src={`data:image/jpeg;base64,${course.images}`} alt={course.images} style={{
                                                            width: "100%",
                                                            height: "200px",
                                                        }} />
                                                    </div>
                                                    <div
                                                        style={{
                                                            marginTop:"-25px",
                                                            backgroundColor: "rgba(255, 255, 255, 0.8)",  // Slightly transparent white background for readability
                                                            width: "100%",
                                                            textAlign: "left",
                                                            color: "black"
                                                        }}
                                                    >
                                                        <h5 style={{ textAlign: "center", background: "black", color: "white" }}>{course.courseName}</h5>
                                                        <p style={{ fontSize: "12px" }}>{course.courseDescription}</p>
                                                        <span className="badge">{course.status}</span><br/><br/>
                                                        <div style={{display:"flex",justifyContent:"center",padding:"10px"}}>
                                                        <Button className="headerBackground" style={{borderRadius:"20px",color:"white"}}>Start Course</Button>
                                                        </div>
                                                    </div>
                                                </div><br />
                                            </>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </>
                    )
                }
            </section>
        </>
    );
};

export default MyCourses;
