import React, { useEffect, useRef, useState } from 'react';
import CourseOverview from '../components/course/CourseOverview';
import CourseInfoWidget from '../components/widgets/CourseInfoWidget';
import SocialShareWidget from '../components/widgets/SocialShareWidget';
import { Link } from 'react-router-dom';
import basic from "../Photos/Package/Basic_Package.png"
import advanced from "../Photos/Package/Advanced_Package.png"
import silver from "../Photos/Package/Silver_Package.png"
import Gold from "../Photos/Package/Gold_Package.png"
import Platinum from "../Photos/Package/Platinum_Package.png"

const PackageDetailPage = () => {
    const runOnce = useRef(false);
    const [packageDetailData, setPackageDetailPage] = useState({
        "packageId": 0,
        "packageName": "",
        "packageDescription": "Coming Soon",
        "postarLink": basic,
        "packagePrice": 12000,
        "discountedPrice": 8500,
        "price": 6400
    })
    useEffect(() => {
        if (runOnce.current === false) {
            const storedProfile = localStorage.getItem("userProfile");
            const userProfile = JSON.parse(storedProfile ?? "");
            if(userProfile.status>3){
                window.location.href = "/dashboard";
            }
            var storedPackages = localStorage.getItem("packageDetail");
            var packagesData = JSON.parse(storedPackages ?? "");
            setPackageDetailPage(packagesData);
            runOnce.current = true;
        }
    }, [runOnce])
    return <>
        <div className='space-ptb course-details'>
        <div className='container'>
                <div className='row'>
                    <div className='col-md-7 col-xl-8'>
                        {packageDetailData.packageId === 1 ?
                            <img className="img-fluid  mb-4" src={basic} alt={basic} style={{ width: "100%", height: "400px", borderRadius: "20px" }} />
                            : packageDetailData.packageId === 2 ?
                                <img className="img-fluid  mb-4" src={advanced} alt={advanced} style={{ width: "100%", height: "400px", borderRadius: "20px" }} />
                                : packageDetailData.packageId === 3 ?
                                    <img className="img-fluid" src={silver} alt={silver} style={{ width: "100%", height: "400px", borderRadius: "20px" }} />
                                    : packageDetailData.packageId === 4 ?
                                        <img className="img-fluid" src={Gold} alt={Gold} style={{ width: "100%", height: "400px", borderRadius: "20px" }} />
                                        : packageDetailData.packageId === 5 ?
                                            <img className="img-fluid" src={Platinum} alt={Platinum} style={{ width: "100%", height: "400px", borderRadius: "20px" }} />
                                            : <></>
                        }
                        <h4 className="news-title mb-2">{packageDetailData.packageName}</h4>
                        <div className="border mb-4">
                            <h6 className="text-dark px-4 py-2 bg-light mb-0">Description</h6>
                            <div className="p-4 border-top">
                                <span className="lead text-dark fw-6">Course Description</span>
                                <p className="mt-2">
                                   {packageDetailData.packageDescription}
                                </p>
                            </div>
                        </div>
                        <CourseOverview />
                    </div>
                    <div className='col-md-5 col-xl-4 mt-5 mt-md-0'>
                        <div className='widgets'>
                            <div className="widget widget-price">
                                <h6 className="widget-title">Price</h6>
                                <div className="widget-content">
                                    <Link to={'/payment'} className="btn btn-primary" >
                                        Get course
                                    </Link>
                                    <span className="fw-bold text-success lead ms-3">₹{packageDetailData.price}</span>
                                </div>
                            </div>
                            <CourseInfoWidget />
                            <SocialShareWidget />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
};

export default PackageDetailPage;