import { useRef, useState, useCallback, useEffect } from "react";
const packageJson = require('../../../package.json');
const Learning = () => {
    const videoApi = packageJson.videoApiUrl+"BodyLanguage/BodyLanguage_Chapter_2.mp4?Authorization=" 
    const video =
        "-";
    const playerRef = useRef<HTMLVideoElement | null>(null);
    const [videoUrl,setVideoUrl] = useState(videoApi+video)
    const [courses, setCourses] = useState([
        { id: 1, title: "Introduction to Body Language" },
        { id: 2, title: "Understanding Gestures" },
        { id: 3, title: "Facial Expressions Analysis" },
        { id: 4, title: "Building Confidence" },
    ]);

    const [selectedCourse, setSelectedCourse] = useState(courses[0]);
    const [isTrayVisible, setIsTrayVisible] = useState(false); // State for controlling tray visibility

    const onReady = useCallback(() => {
        if (playerRef.current) {
            playerRef.current.currentTime = 780; // Set the playback time
        }
    }, []);

    const runOnce = useRef(false);

    const changeVideo = (course :any) =>{
            setVideoUrl("");
            if (playerRef.current) {
                playerRef.current.src = ""
            }
            setVideoUrl(video);
            if (playerRef.current) {
                playerRef.current.src = videoApi+video;
            }
            playerRef.current?.load();
            playerRef.current?.play();
            setSelectedCourse(course);
            setIsTrayVisible(false);
    }

    useEffect(() => {
        if (!runOnce.current) {
            onReady();
            runOnce.current = true;
        }
    }, [onReady]);

    return (
        <div
            style={{
                position: "relative",
                fontFamily: "Arial, sans-serif",
                overflow: "hidden",
                padding:"0 0 30px 0"
            }}
        >
            {/* Mobile Toggle Button */}
            <button
                onClick={() => setIsTrayVisible(!isTrayVisible)}
                style={{
                    top: "10px",
                    left: "10px",
                    zIndex: 1000,
                    backgroundColor: "#007bff",
                    color: "#fff",
                    border: "none",
                    borderRadius: "10px",
                    padding: "10px",
                    cursor: "pointer",
                    marginLeft: "5px",
                    marginTop: "5px"
                }}
            >
                Chapters
            </button>

            {/* Left Tray (Overlay on Mobile) */}
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "50%",
                    height: "100%",
                    backgroundColor: "#f8f9fa",
                    padding: "10px",
                    overflowY: "auto",
                    transition: "transform 0.3s ease",
                    transform: "translateX(0)",
                    zIndex: 999,
                }}
                hidden={!isTrayVisible}
            >
                <h2
                    style={{
                        fontSize: "1.5rem",
                        color: "#2c3e50",
                        marginBottom: "20px",
                        textAlign: "center",
                    }}
                >
                    Course List
                </h2>
                <button
                    onClick={() => setIsTrayVisible(!isTrayVisible)}
                    style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        zIndex: 1000,
                        backgroundColor: "#007bff",
                        color: "#fff",
                        border: "none",
                        borderRadius: "10px",
                        cursor: "pointer",
                        marginTop: "2px"
                    }}
                >
                    Close
                </button>
                <ul style={{ listStyleType: "none", padding: 0 }}>
                    {courses.map((course) => (
                        <li
                            key={course.id}
                            onClick={() => {
                                changeVideo(course)
                            }}
                            style={{
                                padding: "10px 15px",
                                marginBottom: "10px",
                                cursor: "pointer",
                                backgroundColor:
                                    selectedCourse.id === course.id
                                        ? "#d1e7dd"
                                        : "#fff",
                                border: "1px solid #ddd",
                                borderRadius: "5px",
                                color: "#495057",
                                fontWeight:
                                    selectedCourse.id === course.id
                                        ? "bold"
                                        : "normal",
                            }}
                        >
                            {course.title}
                        </li>
                    ))}
                </ul>
            </div>

            {/* Video Section */}
            <div
                style={{
                    textAlign: "center",
                    backgroundColor: "#fffff",
                    overflow: "hidden",
                }}
            >
                <h1
                    style={{
                        color: "#2c3e50",
                        fontSize: "2rem",
                        marginBottom: "20px",
                    }}
                >
                    {selectedCourse.title}
                </h1>
                <div
                    style={{
                        position: "relative",
                        display: "inline-block",
                        width: "80%",
                        maxWidth: "800px",
                        border: "2px solid #ddd",
                        borderRadius: "10px",
                        overflow: "hidden",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                >
                    <video
                        ref={playerRef}
                        controls
                        controlsList="nodownload"
                        onContextMenu={(e) => e.preventDefault()}
                        style={{
                            width: "100%",
                            height: "100%",
                            border: "none",
                        }}
                    >
                        <source
                            src={videoUrl}
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>

            {/* Responsive Styles */}
            <style>
                {`
                    @media (max-width: 768px) {
                        div[style*="width: 25%"] {
                            display: none;
                        }
                    }
                `}
            </style>
        </div>
    );
};

export default Learning;
