import React from 'react';
import InnerBanner from '../components/InnerBanner';
import AboutSection from '../components/AboutSection';
import AboutFeature from '../components/AboutFeature';
import Header from '../components/Header';

const ContactPage = () => {
    return <>
    
        <Header />
        <InnerBanner pageName='About Us' />
        <AboutSection /> 
        <AboutFeature />
    </>
};

export default ContactPage;