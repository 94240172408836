import React, { useEffect, useRef } from 'react';
import { Button, Input, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextareaAutosize } from "@mui/material";
import { useState } from "react";
import { getAdminPannel } from '../handler/LoginAndSingupHandler';
import UserLoader from '../Dashboard/UserLoader';
import { AddUpdateCourse } from '../handler/coursehandler';

const AdminCourses = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loader, setLoader] = useState(false);
    const [addUpdate, setAddUpdate] = useState("Add Course");
    const [addCoursePannel, setAddCoursePannel] = useState(false);
    type Course = {
        courseId: number;
        courseName: string;
        courseDescription: string;
        packageId: number;
        images: string | null;
    };
    const [updateCourseData, setUpdateCourseData] = useState({
        "courseId": 0,
        "courseName": "",
        "courseDescription": "",
        "packageId": 1,
        "images": null
    })
    // Handle page change
    const handleChangePage = (newPage: any) => {
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const getCourses = async () => {
        setLoader(true);
        var response = await getAdminPannel(5);
        if (response.statusCode === 200) {
            const coursesDetails = response.data.adminPannel.courses;
            setCourses(coursesDetails);
            setFilterData(coursesDetails);
        }
        setLoader(false);
    }
    var runValue = useRef(false);
    useEffect(() => {
        if (runValue.current === false) {
            getCourses();
            runValue.current = true;
        }
    }, [runValue]);
    const [Courses, setCourses] = useState([
        {
            "courseId": 0,
            "courseName": "string",
            "courseDescription": "string",
            "images": "string",
            "packageId": 0,
            "status": "string"
        }
    ]);
    const [filterData, setFilterData] = useState([
        {
            "courseId": 0,
            "courseName": "string",
            "courseDescription": "string",
            "images": "string",
            "packageId": 0,
            "status": "string"
        }
    ]);
    // Slice data for current page
    const paginatedData = filterData.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    const DateTimeDisplay = (isoString: string, dateOnly: boolean = false) => {
        const date = new Date(isoString);

        // Get the day, month name, and year
        const day = date.getDate();
        const monthName = date.toLocaleString("en-US", { month: "long" });
        const year = date.getFullYear();

        // Get the time in 12-hour format with AM/PM
        let hours = date.getHours();
        const isPM = hours >= 12;
        hours = hours % 12 || 12; // Convert to 12-hour format, ensuring 12 is shown for noon and midnight
        const minutes = String(date.getMinutes()).padStart(2, '0'); // Pad minutes with a leading zero if needed
        const amPm = isPM ? "PM" : "AM";
        var formattedDateTime = "";
        if (dateOnly) {
            formattedDateTime = `${day} ${monthName} ${year}`;
        }
        else {
            formattedDateTime = `${day} ${monthName} ${year} ${hours}:${minutes} ${amPm}`;
        }
        // Combine date and time

        return <div>{formattedDateTime}</div>;
    };
    const packages = [
        { packageId: 1, packageName: "Basic Package" },
        { packageId: 2, packageName: "Advanced Package" },
        { packageId: 3, packageName: "Silver Package" },
        { packageId: 4, packageName: "Gold Package" },
        { packageId: 5, packageName: "Platinum Package" },
    ];

    const search = (event: any) => {
        const searchTerm = event.target.value.toLowerCase();
        const filtered = Courses.filter((data) =>
            data.courseName.toLowerCase().includes(searchTerm)
        );
        setFilterData(filtered);
        setPage(0); // Reset to first page
    }

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            setUpdateCourseData((prevData) => ({
                ...prevData,
                ["images"]: file
            }));
        }
    };

    const handelOnChange = (name: string, e: any) => {
        const value = e.target.value;
        setUpdateCourseData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };


    const AddUpdate = async (event: any) => {
        event.preventDefault();
        console.log(updateCourseData);
        setLoader(true);
        await AddUpdateCourse(updateCourseData);
        await getCourses();
        setAddCoursePannel(false);
    }
    const UpdateCourse = async (userData: Course) => {
        console.log(userData);
        setUpdateCourseData({
            "courseId": userData.courseId,
            "courseName": userData.courseName,
            "courseDescription": userData.courseDescription,
            "packageId": userData.packageId,
            "images": null
        });
        setAddUpdate("Update Course");
        setAddCoursePannel(true);
    }
    const AddCourse =()=>{
        setAddUpdate("Add Course");
        setAddCoursePannel(true);
    }
    const [selectedPackage, setSelectedPackage] = useState(1);
    return (
        <>
            {
                loader && (
                    <UserLoader />
                )
            }
            {
                !loader && (
                    <>
                        {!addCoursePannel ? <section className='headerBackground'>
                            <div className='space-ptb achievers-section headerBackground' style={{ borderStyle: "inset", borderColor: "white", padding: "30px" }}>
                                <TableContainer style={{ background: "white" }}>
                                    <h3 style={{ textAlign: "center" }}>Growniverse Users</h3>
                                    <div style={{ padding: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Input placeholder='Search' onChange={search} />
                                    </div>
                                    <div style={{ padding: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <select
                                            id="packageDropdown"
                                            value={selectedPackage}
                                            onChange={(e) => { setSelectedPackage(parseInt(e.target.value)) }}
                                            style={{ padding: "10px", border: "1px solid #343434", borderRadius: "10px", color: "#343434", fontWeight: "bold" }}
                                            className='headerBackground'
                                        >
                                            <option value="" disabled>
                                                Select a package
                                            </option>
                                            {packages.map((pkg) => (
                                                <option key={pkg.packageId} value={pkg.packageId}>
                                                    {pkg.packageName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div style={{ padding: "10px", display: "flex", justifyContent: "right", alignItems: "center" }}>
                                        <Button style={{ background: "#B4E28E", padding: "10px", borderRadius: "10px" }} onClick={AddCourse}>Add Course</Button>
                                    </div>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>CoursesId</TableCell>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>Courses Name</TableCell>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>Course Description</TableCell>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>Package Id</TableCell>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>Image</TableCell>
                                                <TableCell align="center" style={{ fontWeight: "bold" }}>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {paginatedData.map(statement => (
                                            statement.packageId === selectedPackage && (
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell align="center">
                                                            {statement.courseId}
                                                        </TableCell>
                                                        <TableCell align="center">{statement.courseName}</TableCell>
                                                        <TableCell align="center">{statement.courseDescription}</TableCell>
                                                        <TableCell align="center">
                                                            {statement.packageId === 1 ? "Basic Package"
                                                                : statement.packageId === 2 ? "Advanced Package"
                                                                    : statement.packageId === 3 ? "Silver Package"
                                                                        : statement.packageId === 4 ? "Gold Package"
                                                                            : "Platinum Package"}</TableCell>
                                                        <TableCell align="center"><img src={`data:image/jpeg;base64,${statement.images}`} alt="Decoded from Base64" style={{ width: "300px", height: "100%" }} /></TableCell>
                                                        <TableCell align="center">
                                                            <Button style={{ background: "lightblue", borderRadius: "10px" }} onClick={() => { UpdateCourse(statement) }}>Update</Button>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            )
                                        ))}
                                    </Table>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 20]}
                                        component="div"
                                        count={Courses.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableContainer>
                            </div>
                        </section> :
                            <section style={{ padding: "15% 50px 50px 50px", width: "100%", height: "100%", position: "fixed", top: "0", background: "rgba(0, 0, 0, .8)" }}>
                                <div style={{ background: "#3434", height: "80%" }}>
                                    <form onSubmit={AddUpdate}>
                                        <TableContainer style={{ background: "white" }}>
                                            <Table>
                                                <TableHead>
                                                    <TableCell align='center' style={{ fontWeight: "bold" }}>
                                                        Course Name
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <Input placeholder='Course Name' value={updateCourseData.courseName} onChange={(e) => { handelOnChange("courseName", e) }} required />
                                                    </TableCell>
                                                </TableHead>
                                                <TableHead>
                                                    <TableCell align='center' style={{ fontWeight: "bold" }}>
                                                        Course Description
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <TextareaAutosize value={updateCourseData.courseDescription} onChange={(e) => { handelOnChange("courseDescription", e) }} placeholder='Course Description' required />
                                                    </TableCell>
                                                </TableHead>
                                                <TableHead>
                                                    <TableCell align='center' style={{ fontWeight: "bold" }}>
                                                        Package Id
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <div>
                                                            <select
                                                                id="packageDropdown"
                                                                value={updateCourseData.packageId}
                                                                onChange={(e) => { handelOnChange("packageId", e) }}
                                                                style={{ padding: "10px", border: "1px solid #343434", background: "#B4EF9D", borderRadius: "10px" }}
                                                            >
                                                                <option value="" disabled>
                                                                    Select a package
                                                                </option>
                                                                {packages.map((pkg) => (
                                                                    <option key={pkg.packageId} value={pkg.packageId}>
                                                                        {pkg.packageName}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </TableCell>
                                                </TableHead>
                                                <TableHead>
                                                    <TableCell align='center' style={{ fontWeight: "bold" }}>
                                                        Course Image
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Input name='imageFileData' type="file" onChange={handleFileChange} />
                                                    </TableCell>
                                                </TableHead>
                                            </Table>
                                            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", paddingTop: "40px" }}>
                                                <Button style={{ background: "red", color: "white", padding: "10px", borderRadius: "10px" }} onClick={() => setAddCoursePannel(false)}>Close</Button>
                                                <Button className='headerBackground' style={{ color: "white", padding: "10px", borderRadius: "10px" }} type='submit'>{addUpdate}</Button>
                                            </div><br />
                                        </TableContainer>
                                    </form>
                                </div>
                            </section>}
                    </>
                )
            }
        </>
    );
};

export default AdminCourses;
