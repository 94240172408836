import { useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import GrowniverseLogo from "../Photos/Logo/growniverse-logo-full-bordered.svg";
import { activateEmailAsync } from '../handler/LoginAndSingupHandler';
const EmailConfirmation = () => {
    const location = useLocation();
    const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const runOnce = useRef(false);
    const confirmEmail = async (userData: any) => {
        
        const response = await activateEmailAsync(userData);
        if (response.statusCode === 200) {
            var userData = response.data;
            localStorage.setItem("appkey", userData.appKey)
            window.location.href = "/package";
        }
    }
    useEffect(() => {
        if (runOnce.current === false) {
            const userId = query.get("userId");
            const token = query.get("token");
            const appKey = localStorage.getItem("appkey");
            if ((userId && token) || (appKey && appKey !== "undefined")) {
                if (userId && token) {
                    var userData = {
                        "userId": userId,
                        "confirmationToken": token
                    }
                    confirmEmail(userData);
                }
            }
            else {
                window.location.href = "/";
            }
            runOnce.current = true;
        }
    }, [runOnce]);
    return <>
        <section style={{ background: "#343434", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className="headerBackground" style={{
                height: "400px", width: "80%", borderRadius: "30px",
                display: "flex", justifyContent: "center", alignItems: "center", margin: "170px 30px 170px 30px"
            }}>
                <p style={{ textAlign: "center", fontSize: "20px", font: "Sans-serif", color: "white" }}>
                    {query.get("userId") && (
                        <>
                            <p>
                                Your Email Confirmation is Under Process, Please Stay on this page.
                            </p>
                        </>
                    )}
                    {(localStorage.getItem("appkey") !== "" && localStorage.getItem("appkey") !== "undefined") && (
                        <>
                            <p>
                                <h2 style={{ color: "white" }}>Please Confirm your Mail</h2>
                                <h4>We Sent you a Mail, Please check and confirm First.</h4>
                            </p>
                        </>
                    )}
                    <img src={GrowniverseLogo} width={"20%"} alt='Growniverse' />
                </p>
            </div>
        </section>
    </>
};
export default EmailConfirmation;