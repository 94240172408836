import React, { useEffect, useRef, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const runOn = useRef(false);
  useEffect(() => {
    if (runOn.current === false) {
      runOn.current = true;
    }
  });
  return (
    <>
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
