import React from 'react';
import InnerBanner from '../components/InnerBanner';
import GetInTouch from '../components/GetInTouch';
import AdditionalContactInfo from '../components/AdditionalContactInfo';
import Header from '../components/Header';
const ContactPage = () => {
    return <>

        <Header />
        <InnerBanner pageName='Contact Us' />
        <GetInTouch />
        <AdditionalContactInfo />
    </>
};

export default ContactPage;