import Avatar from "react-avatar";
import "../components/WelcomeSection.css";
import { useEffect, useRef, useState } from "react";
import "../Dashboard/Design/Dashboard.css"
import { heighestEarnerOfWeek } from "../handler/accountHandler";
import UserLoader from "./UserLoader";
import medal1 from "../Photos/Achievers/medal1.png";
import medal2 from "../Photos/Achievers/medal2.png";
import medal3 from "../Photos/Achievers/medal3.png";
const HeighestEarnerOfTheWeek = () => {
    const [loader, setLoader] = useState(true);
    const heighestEarnerWeekly = [{
        userInformationId: 1,
        growniverseId: "G10021",
        fullName: "Admin Growniverse",
        income: 0,
        profileImage: ""
    }]
    const [heighestEarner, setHeighestEarnerWeek] = useState(heighestEarnerWeekly);
    const heighestEarnerGet = async () => {

        var response = await heighestEarnerOfWeek();
        if (response.statusCode === 200) {
            setHeighestEarnerWeek(response.data.heighestEarnerOfWeekDtos)
            setLoader(false);
        }
    }
    const runOnce = useRef(false);
    useEffect(() => {
        if (runOnce.current === false) {
            heighestEarnerGet();
            runOnce.current = true;
        }
    }, [runOnce]);
    const formatRupees = (amount: number) => {
        return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(amount);
    };
    return <>
        <section style={{ width: "100%" }} className="headerBackground">
            {
                loader && (
                    <UserLoader />
                )
            }
            {
                !loader && (
                    <div className="container"><br />
                        <div className="headerBackgroundWithShades" style={{ border: "3px solid #b77f5a", borderRadius: "20px", textAlign: "center" }}>
                            <h2 style={{ color: "white" }}>Highest Earner's of The Week</h2>
                        </div><br />
                        {heighestEarner.map((earner, index) => (
                            <>
                                {
                                    (index === 0 && earner.income > 0) && (
                                        <>
                                            <div className="headerBackground" style={{ borderRadius: "20px", padding: "10px", border: "5px solid #b77f5a" }}>
                                                <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                                    {
                                                        earner.profileImage && (
                                                            <img src={`data:image/jpeg;base64,${earner.profileImage}`} alt="Decoded from Base64" style={{ border: "3px solid #b77f5a", width: "100px", height: "100px", borderRadius: "60px" }} />
                                                        )
                                                    }
                                                    {
                                                        !earner.profileImage && (
                                                            <Avatar name={earner.fullName} size="100" round={true} style={{ border: "3px solid #b77f5a" }} />
                                                        )
                                                    }

                                                </div>
                                                <div style={{ textAlign: "center" }}>
                                                    <div className="headerBackgroundWithShades" style={{ color: "white", borderRadius: "30px", width: "350px", display: "inline-grid", border: "5px solid #b77f5a", paddingTop: "0px" }}>
                                                        <div
                                                            style={{
                                                                color: "white",
                                                                borderRadius: "20px",
                                                                width: "100%",
                                                                display: "flex",
                                                                justifyContent: "center", // Centers content horizontally initially
                                                                alignItems: "center", // Aligns content vertically
                                                                border: "3px solid #b77f5a",
                                                                padding: "10px",
                                                                position: "relative" // Allows for precise placement of the image
                                                            }}
                                                            className="headerBackgroundWithShades"
                                                        >
                                                            <h1
                                                                style={{
                                                                    color: "#Daa425",
                                                                    textShadow: "0 0 3px #FF0000, 0 0 5px #0000FF",
                                                                    margin: 0, // Removes extra margin from h3
                                                                    flex: 1, // Takes remaining space for proper centering
                                                                    textAlign: "center" // Ensures text is centered within its space
                                                                }}
                                                            >
                                                                {formatRupees(earner.income)}
                                                            </h1>
                                                            {
                                                                index === 0 ? <img
                                                                    src={medal1}
                                                                    alt="Decoded from Base64"
                                                                    style={{
                                                                        width: "50px",
                                                                        height: "50px",
                                                                        position: "absolute", // Places the image independently
                                                                        right: "10px", // Moves image to the far right
                                                                        top: "50%", // Centers the image vertically
                                                                        transform: "translateY(-50%)" // Corrects for image height
                                                                    }}
                                                                /> : index === 1 ? <img
                                                                    src={medal2}
                                                                    alt="Decoded from Base64"
                                                                    style={{
                                                                        width: "50px",
                                                                        height: "50px",
                                                                        position: "absolute", // Places the image independently
                                                                        right: "10px", // Moves image to the far right
                                                                        top: "50%", // Centers the image vertically
                                                                        transform: "translateY(-50%)" // Corrects for image height
                                                                    }}
                                                                /> : index === 2 ? <img
                                                                    src={medal3}
                                                                    alt="Decoded from Base64"
                                                                    style={{
                                                                        width: "50px",
                                                                        height: "50px",
                                                                        position: "absolute", // Places the image independently
                                                                        right: "10px", // Moves image to the far right
                                                                        top: "50%", // Centers the image vertically
                                                                        transform: "translateY(-50%)" // Corrects for image height
                                                                    }}
                                                                /> : ""
                                                            }
                                                        </div>

                                                        <div style={{ color: "white", width: "100%", display: "inline-grid" }} >
                                                            <h2 style={{ color: "#Daa425", textShadow: "0 0 3px #FF0000, 0 0 5px #0000FF" }}>{earner.fullName}</h2>
                                                            <h3 style={{ color: "#Daa425", textShadow: "0 0 3px #FF0000, 0 0 5px #0000FF" }}>{earner.growniverseId}</h3>
                                                        </div>
                                                    </div>
                                                </div><br />
                                            </div><br />
                                        </>
                                    )
                                }
                            </>
                        ))}
                    </div>
                )
            }

        </section>
    </>
};

export default HeighestEarnerOfTheWeek;