import axios from "axios";
const packageJson = require('../../package.json');
const createSignature = () => {
    const secretKey = "5d928cd2-77d1-4415-abbb-4f225f40ee65"
    return secretKey;
}

export const GetUserCourses = async (packageId: number) => {
    
    const signature = createSignature();
    const response = await axios.get(packageJson.apiUrl+"Courses?PackageId="+packageId, {
        headers: {
            "Content-Type": "application/json",
            'X-Growniverse-Signature': signature
        },
    });
    return response.data;
}

export const AddUpdateCourse = async (userData: any) => {
    
    const signature = createSignature();
    const response = await axios.patch(packageJson.apiUrl+"Courses/AddUpdate",userData, {
        headers: {
            "Content-Type": "multipart/form-data",
            'X-Growniverse-Signature': signature
        },
    });
    return response.data;
}