import React, { useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import { useState } from "react";
import axios from 'axios';
interface InnerBannerProps {
    userReferral: {
        growniverseId: string,
        fullName: string,
        packageName: string,
        state: string,
        joiningDate: string,
    }[],
    teamLevel: string;
}

const UserReferral: React.FC<InnerBannerProps> = ({ userReferral, teamLevel }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    // Handle page change
    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Slice data for current page
    const paginatedData = userReferral.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    const DateTimeDisplay = (isoString: string) => {
        const date = new Date(isoString);
    
        // Get the day, month name, and year
        const day = date.getDate();
        const monthName = date.toLocaleString("en-US", { month: "long" });
        const year = date.getFullYear();
    
        // Get the time in 12-hour format with AM/PM
        let hours = date.getHours();
        const isPM = hours >= 12;
        hours = hours % 12 || 12; // Convert to 12-hour format, ensuring 12 is shown for noon and midnight
        const minutes = String(date.getMinutes()).padStart(2, '0'); // Pad minutes with a leading zero if needed
        const amPm = isPM ? "PM" : "AM";
    
        // Combine date and time
        const formattedDateTime = `${day} ${monthName} ${year} ${hours}:${minutes} ${amPm}`;
    
        return <div>{formattedDateTime}</div>;
    };
    type State = {
        name: string;
        state_code: string;
      };
    const [states, setStates] = useState<State[]>([]);
    const fetchCountries = async () => {
        try {
    
          const response = await axios.get("https://countriesnow.space/api/v0.1/countries/states");
          const selectedCountryData = response.data.data.find((c: any) => c.name === "India");
    
          if (selectedCountryData) {
            setStates(selectedCountryData.states);
          } else {
            setStates([]);
          }
        } catch (error) {
          console.error("Error fetching countries:", error);
        }
      };
    useEffect(()=>{
        fetchCountries();
    })
    return (
        <section className='headerBackground'>
            <div className='space-ptb achievers-section headerBackground' style={{ borderStyle: "inset", borderColor: "white", padding: "30px" }}>
                <TableContainer style={{ background: "white" }}>
                    <h3 style={{ textAlign: "center",color:"white",background:"black" }}>{teamLevel}</h3>
                    <Table>
                        <TableHead>
                            <TableRow >
                                <TableCell align="center">S.no</TableCell>
                                <TableCell align="center">Growniverse Id</TableCell>
                                <TableCell align="center">Full Name</TableCell>
                                <TableCell align="center">Package</TableCell>
                                <TableCell align="center">State</TableCell>
                                <TableCell align="center">Joining Date <br/>& Time</TableCell>
                            </TableRow>
                        </TableHead>
                        {paginatedData.map((referral, index) => (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell align="center">{referral.growniverseId}</TableCell>
                                    <TableCell align="center">{referral.fullName}</TableCell>
                                    <TableCell align="center">{referral.packageName}</TableCell>
                                    <TableCell align="center">{states.find(data=>data.state_code===referral.state)?.name}</TableCell>
                                    <TableCell align="center">{DateTimeDisplay(referral.joiningDate)}</TableCell>
                                </TableRow>
                            </TableBody>
                        ))}
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[10, 20]}
                        component="div"
                        count={userReferral.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </div>
        </section>
    );
};

export default UserReferral;
