import React, { useEffect, useRef } from 'react';
import { useState } from "react";
import UserReferral from '../components/UserReferral';
import { GetUserReferral } from '../handler/LoginAndSingupHandler';
import { Button } from '@mui/material';
import '../Dashboard/Design/Dashboard.css';
import UserLoader from './UserLoader';
const Team = () => {
    const [loader, setLoader] = useState(false);
    const [pannel, setPannel] = useState("Direct Referral");
    const [userReferral, setuserReferral] = useState({
        "directReferral": [
            {
                "growniverseId": "",
                "fullName": "",
                "packageName": "",
                "state": "",
                "joiningDate": ""
            }
        ],
        "firstLevel": [
            {
                "growniverseId": "",
                "fullName": "",
                "packageName": "",
                "state": "",
                "joiningDate": ""
            }
        ],
        "secondLevel": [
            {
                "growniverseId": "",
                "fullName": "",
                "packageName": "",
                "state": "",
                "joiningDate": ""
            }
        ],
        "thirdLevel": [{
            "growniverseId": "",
            "fullName": "",
            "packageName": "",
            "state": "",
            "joiningDate": ""
        }]
    })
    const getUserReferrals = async (growniverseId: string) => {
        var response = await GetUserReferral(growniverseId);
        if (response.statusCode === 200) {
            setuserReferral(response.data.userReferral)
        }
        setLoader(false);
    }
    const typeOfTestimonial = [
        'Level 1',
        'Level 2',
        'Level 3',
    ];
    const handleFilter = (type: any) => {
        setPannel(type);
    };
    const hasRun = useRef(false);
    useEffect(() => {

        if (!hasRun.current) {
            setLoader(true);
            const storedProfile = localStorage.getItem("userProfile");
            const userProfile = JSON.parse(storedProfile ?? "");
            getUserReferrals(userProfile.growniverseId);
            hasRun.current = true;
        }
    }, [hasRun])
    return (
        <section className='headerBackground'>
            <h3 style={{ textAlign: "center", color: "white", background: "black" }}>My Team</h3>
            {
                loader && (
                    <UserLoader />
                )
            }
            {!loader && (
                <div className='space-ptb achievers-section' style={{ borderStyle: "inset", borderColor: "white", padding: "30px" }}>
                    <div className="filters-group mb-2 mb-4">
                        {typeOfTestimonial.map((type) => (
                            <div>
                                <Button className='headerBackground' style={{ border: "1px solid white", color: "white" }}
                                    key={type}
                                    onClick={() => handleFilter(type)}
                                >
                                    {type.charAt(0).toUpperCase() + type.slice(1)}
                                </Button><br /><br />
                            </div>
                        ))}
                    </div>
                    {pannel === "Level 1" && (
                        <UserReferral userReferral={userReferral.directReferral} teamLevel='Level 1' />
                    )}
                    {pannel === "Level 2" && (
                        <UserReferral userReferral={userReferral.firstLevel} teamLevel='Level 2' />
                    )}
                    {pannel === "Level 3" && (
                        <UserReferral userReferral={userReferral.secondLevel} teamLevel='Level 3' />
                    )}
                </div>
            )}
        </section>
    );
};

export default Team;
