import { Button, Input, Table, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { userUpdate } from "../handler/LoginAndSingupHandler";
import UserLoader from "../Dashboard/UserLoader";

const UpdateProfile = () => {
    const [file, setFile] = useState(null);
    const [loader, setLoader] = useState(false);
    
    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const fileSizeInMB = file.size / (1024 * 1024); // Convert size to MB
            if (fileSizeInMB > 1) {
                alert("File size exceeds 1 MB. Please select a smaller file.");
                event.target.value = ""; // Clear the file input
                return;
            }
            setFile(file);
        }
    };
    const updateProfile = async () => {
        setLoader(true);
        const formData = new FormData();
        if (!file) {
            alert('Please select a file before submitting.');
            setLoader(false);
            return;
        }
        formData.append('UserInformationId', userProfile.userInformationId.toString());
        formData.append('ProfileImage', file);
        var response = await userUpdate(formData);
        if (response.statusCode === 200) {
            window.location.href = "/dashboard";
        }
        else {
            alert("Something went Wrong, please upload again");
        }
    }
    const userProfileData = {
        appToken: "",
        userInformationId: 5,
        fullName: "",
        sponsorGrowniverseId: "",
        growniverseId: "",
        address: "string",
        country: "India",
        state: null,
        pincode: 0,
        userIdentityGuid: "ef035009-7095-4a70-8882-63a86f062681-5bd0bcdb-a6d8-47a5-9c81-708f75c0b95f",
        emailID: "string",
        phoneNumber: "string",
        emailConfirmation: true,
        packageId: 0,
        packageName: "",
        isAdmin: false,
        profileImage: ""
    };
    const [userProfile, setUserProfile] = useState(userProfileData);
    const runOnce = useRef(false);
    useEffect(() => {
        if (runOnce.current === false) {
            const storedProfile = localStorage.getItem("userProfile");
            const userProfile = JSON.parse(storedProfile ?? "");
            setUserProfile(userProfile);
            runOnce.current = true;
        }
    }, [runOnce]);
    return (
        <>
            {loader ? (
                <UserLoader />
            ) : <TableContainer style={{ background: "white" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"><Input name='imageFileData' type="file" onChange={handleFileChange} required /></TableCell>
                            <TableCell align="center"><Button className="headerBackground" style={{ color: "white" }} onClick={updateProfile}>Upload</Button></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center">Change Password</TableCell>
                            <TableCell align="center"><Button className="headerBackground" ><Link to='/adminchangepassword' style={{ color: "white" }}>Change Password</Link></Button></TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>}

        </>
    );
};

export default UpdateProfile;
