import "./Design/Dashboard.css"
import { useEffect, useRef, useState } from "react";
import AccountStatements from "../components/AccountStatements";
import WithDraw from "./WithDraw";
import { Alert, AlertTitle, Button, Input, Stack, Table, TableCell, TableContainer, TableHead } from "@mui/material";
import { WithDrawRequest, getAccountDetails } from "../handler/accountHandler";
import "./Design/Dashboard.css"
import UserLoader from "./UserLoader";


const Payouts = () => {
    const [localWithDraw, setLocalWithDraw] = useState(false);
    const [minimumWithDraw, setMinimumWithDraw] = useState(false);
    const userProfileData = {
        appToken: "",
        userInformationId: 5,
        fullName: "Robin Sharma",
        sponsorGrowniverseId: "string",
        growniverseId: "G10025",
        address: "string",
        country: "India",
        state: null,
        pincode: 0,
        userIdentityGuid: "ef035009-7095-4a70-8882-63a86f062681-5bd0bcdb-a6d8-47a5-9c81-708f75c0b95f",
        emailID: "string",
        phoneNumber: "string",
        emailConfirmation: true,
        packageId: 0,
        packageName: "",
        status: 0
    };
    const [userProfile, setUserProfile] = useState(userProfileData);
    const [accountData, setAccountData] = useState({
        accountId: 0,
        unPaidBalance: 0,
        totalIncome: 0,
        todayIncome: 0,
        sevenDayIncome: 0,
        monthlyIncome: 0,
        directIncome: 0,
        passiveIncome: 0,
        transactions: {
            transactionId: 0,
            amount: 0,
            status: 0,
            transactionTime: "2025-12-09T19:11:34.225688"
        },
        accountStatements: [],
    });

    const [loader, setLoader] = useState(false);

    const crateWithDrawRequest = async () => {
        setLoader(true);
        if (accountData.unPaidBalance >= 500 && (!accountData.transactions)) {
            const userData = {
                "userInformationId": userProfile.userInformationId,
                "amount": accountData.unPaidBalance
            }
            var response = await WithDrawRequest(userData);
            if (response.statusCode === 200) {
                accountData.transactions = response.data.accountTransactionsDto;
                localStorage.setItem("account", JSON.stringify(accountData));
                setAccountData(accountData);
                setLocalWithDraw(true);
            }
        }
        else {
            setMinimumWithDraw(true);
        }
        setLoader(false);
    }
    const getAccountDetail = async (userInformationId: number) => {
        setLoader(true);
        var response = await getAccountDetails(userInformationId);
        if (response.statusCode === 200) {
            setAccountData(response.data.account);
            if (response.data.account.transactions) {
                setLocalWithDraw(true)
            }
            setLoader(false);
        }
    }

    const runOnce = useRef(false);
    useEffect(() => {
        if (runOnce.current === false) {
            const storedProfile = localStorage.getItem("userProfile");
            const userProfile = JSON.parse(storedProfile ?? "");
            setUserProfile(userProfile);
            getAccountDetail(userProfile.userInformationId);
            runOnce.current = true;
        }
    }, [runOnce]);
    const formatRupees = (amount: number) => {
        return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(amount);
    };
    return <>
        {
            loader && (
                <UserLoader />
            )
        }
        {
            !loader && (
                <>
                    <section className="headerBackgroundReverse" style={{ padding: "30px", background: "" }}>
                        <div style={{ border: "2px solid white", width: "", display: "flex", justifyContent: "space-between", padding: "20px", background: "" }}>
                            <div>
                                <Input value={formatRupees(accountData.unPaidBalance)} style={{ color: "white", fontSize: "15px" }} /><br />
                                <span style={{ color: "white" }}>Un-Paid Balance</span>
                            </div>
                            {
                                userProfile.status === 6 && (<>
                                    <Button className="directpassive" style={{ color: "white" }} onClick={crateWithDrawRequest} disabled={localWithDraw}>WithDraw</Button>
                                </>)
                            }
                            {
                                userProfile.status !== 6 && (
                                    <>
                                        <div className="marquee">
                                            <span style={{ color: "blue", fontSize: "20px" }}>🚀 Kyc Pending, Please Complete your KYC if you want to WithDraw 🌟</span>
                                        </div>
                                    </>
                                )
                            }

                        </div>

                        {localWithDraw && (
                            <>
                                <Stack spacing={2} sx={{ width: '100%' }}>
                                    <Alert severity="success">
                                        <AlertTitle>WithDraw Request Submit Successfully</AlertTitle>
                                        Your WithDraw Request of ₹{accountData.transactions ? accountData.transactions.amount : ""} is in Process...
                                    </Alert>
                                </Stack>
                            </>
                        )
                        }
                        {minimumWithDraw && (
                            <>
                                <Stack spacing={2} sx={{ width: '100%' }}>
                                    <Alert severity="error">
                                        <AlertTitle>Minumum Balance</AlertTitle>
                                        Minumum WithDraw Balance Should Be 500
                                    </Alert>
                                </Stack>
                            </>
                        )
                        }
                    </section>
                    <div className="directpassive" style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "40px" }}>
                        <TableContainer style={{ background: "smokewhite", borderRadius: "30px", border: "3px solid #b77f5a" }}>
                            <Table>
                                <TableHead>
                                    <TableCell align="center" style={{ color: "white" }}>Direct Income</TableCell>
                                    <TableCell align="center" style={{ color: "white" }}>{formatRupees(accountData.directIncome)}</TableCell>
                                </TableHead>
                                <TableHead>
                                    <TableCell align="center" style={{ color: "white" }}>Passive Income</TableCell>
                                    <TableCell align="center" style={{ color: "white" }}>{formatRupees(accountData.passiveIncome)}</TableCell>
                                </TableHead>
                            </Table>
                        </TableContainer>
                    </div>
                    <AccountStatements accountStatement={accountData.accountStatements} />
                </>
            )
        }
    </>
};

export default Payouts;