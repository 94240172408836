import React, { useEffect, useRef, useState } from 'react';

import "../components/WelcomeSection.css"
import DashboardProfile from './DashboardProfile';
import { Button, Input, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { userUpdate } from "../handler/LoginAndSingupHandler";
import UserLoader from '../Dashboard/UserLoader';
import { Link } from 'react-router-dom';
const UserProfile = () => {
    const [file, setFile] = useState(null);
    const [loader, setLoader] = useState(false);
    // Handle file upload

    // Handle file upload
    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const fileSizeInMB = file.size / (1024 * 1024); // Convert size to MB
            if (fileSizeInMB > 1) {
                alert("File size exceeds 1 MB. Please select a smaller file.");
                event.target.value = ""; // Clear the file input
                return;
            }
            setFile(file);
        }
    };
    const updateProfile = async () => {
        setLoader(true);
        const formData = new FormData();
        if (!file) {
            alert('Please select a file before submitting.');
            setLoader(false);
            return;
        }
        formData.append('UserInformationId', userProfile.userInformationId.toString());
        formData.append('ProfileImage', file);
        var response = await userUpdate(formData);
        if (response.statusCode === 200) {
            window.location.href = "/dashboard";
        }
        else {
            alert("Something went Wrong, please upload again");
        }
    }
    const userProfileData = {
        appToken: "",
        userInformationId: 5,
        fullName: "Robin Sharma",
        sponsorGrowniverseId: "string",
        growniverseId: "G10025",
        address: "string",
        country: "India",
        state: null,
        pincode: 0,
        userIdentityGuid: "ef035009-7095-4a70-8882-63a86f062681-5bd0bcdb-a6d8-47a5-9c81-708f75c0b95f",
        emailID: "string",
        phoneNumber: "string",
        emailConfirmation: true,
        packageId: 0,
        packageName: "",
        isAdmin: false,
        profileImage: "string",
        registrationDate : "",
        dateOfBirth :""
    };
    const [userProfile, setUserProfile] = useState(userProfileData);
    const [accountData, setAccountData] = useState({
        accountId: 1,
        unPaidBalance: 5840,
        totalIncome: 5840,
        todayIncome: 40,
        sevenDayIncome: 5840,
        monthlyIncome: 5840,
        transactions: [],
        accountStatements: [
            {
                accountStatementId: 1,
                credit: 5500,
                debit: 0,
                dateTime: "2025-12-09T19:11:34.225688",
            },
            {
                accountStatementId: 5,
                credit: 150,
                debit: 0,
                dateTime: "2025-12-09T20:07:43.113062",
            },
            {
                accountStatementId: 9,
                credit: 150,
                debit: 0,
                dateTime: "2025-12-09T20:12:43.656522",
            },
            {
                accountStatementId: 15,
                credit: 40,
                debit: 0,
                dateTime: "2025-12-10T15:26:20.113517",
            },
        ],
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const runOnce = useRef(false); 
    const DateTimeDisplay = (isoString: string, onlyDate:boolean) => {
        const date = new Date(isoString);
    
        // Get the day, month name, and year
        const day = date.getDate();
        const monthName = date.toLocaleString("en-US", { month: "long" });
        const year = date.getFullYear();
    
        // Get the time in 12-hour format with AM/PM
        let hours = date.getHours();
        const isPM = hours >= 12;
        hours = hours % 12 || 12; // Convert to 12-hour format, ensuring 12 is shown for noon and midnight
        const minutes = String(date.getMinutes()).padStart(2, '0'); // Pad minutes with a leading zero if needed
        const amPm = isPM ? "PM" : "AM";
    
        // Combine date and time
        var formattedDateTime = '';
        if(onlyDate)
        {
            formattedDateTime = `${day} ${monthName} ${year}`;
        }
        else{
            formattedDateTime = `${day} ${monthName} ${year} ${hours}:${minutes} ${amPm}`;
        }
        return <div>{formattedDateTime}</div>;
    };
    useEffect(() => {
        if (runOnce.current === false) {
            
            const storedProfile = localStorage.getItem("userProfile");
            const storedAccount = localStorage.getItem("account");
            const userProfile = JSON.parse(storedProfile ?? "");
            const account = JSON.parse(storedAccount ?? "");
            setUserProfile(userProfile);
            setAccountData(account);
            runOnce.current = true;
        }
    }, [runOnce]);
    return (
        <>
            <section className='headerBackground'>
                {
                    loader && (
                        <div>
                            <UserLoader />
                        </div>
                    )
                }
                {
                    !loader && (
                        <>
                            <DashboardProfile userProfile={userProfile} />
                            <div className='space-ptb achievers-section headerBackground' style={{ borderStyle: "inset", borderColor: "white", padding: "30px" }}>
                                <TableContainer style={{ background: "white" }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="right">Upload Profile Photo</TableCell>
                                                <TableCell align="center"><Input name='imageFileData' type="file" onChange={handleFileChange} required /></TableCell>
                                                <TableCell align="center"><Button className="headerBackground" style={{ color: "white" }} onClick={updateProfile}>Upload</Button></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right"></TableCell>
                                                <TableCell align="center">Change Password</TableCell>
                                                <TableCell align="center"><Button className="headerBackground" ><Link to='/changepassword' style={{ color: "white" }}>Change Password</Link></Button></TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="right">1</TableCell>
                                                <TableCell align="center">Growniverse Id</TableCell>
                                                <TableCell align="center">{userProfile.growniverseId}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="right">2</TableCell>
                                                <TableCell align="center">Sponsor Growniverse Id</TableCell>
                                                <TableCell align="center">{userProfile.sponsorGrowniverseId}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="right">3</TableCell>
                                                <TableCell align="center">Package</TableCell>
                                                <TableCell align="center">{userProfile.packageName}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="right">4</TableCell>
                                                <TableCell align="center">Registration Date & Time</TableCell>
                                                <TableCell align="center">{DateTimeDisplay(userProfile.registrationDate,false)}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="right">5</TableCell>
                                                <TableCell align="center">Full Name</TableCell>
                                                <TableCell align="center">{userProfile.fullName}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="right">6</TableCell>
                                                <TableCell align="center">Email-Id</TableCell>
                                                <TableCell align="center">{userProfile.emailID}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="right">7</TableCell>
                                                <TableCell align="center">Phone Number</TableCell>
                                                <TableCell align="center">{userProfile.phoneNumber}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="right">8</TableCell>
                                                <TableCell align="center">Date Of Birth</TableCell>
                                                <TableCell align="center">{DateTimeDisplay(userProfile.dateOfBirth,true)}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="right">9</TableCell>
                                                <TableCell align="center">Address</TableCell>
                                                <TableCell align="center">{userProfile.address},{userProfile.state} ({userProfile.pincode})</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="right">10</TableCell>
                                                <TableCell align="center">Agreement</TableCell>
                                                <TableCell align="center"><Link to='/user-agreement' style={{color:"blue"}}>Link</Link></TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </TableContainer>
                            </div>
                        </>
                    )
                }
            </section>
        </>
    );
};

export default UserProfile;
