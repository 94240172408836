import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/assets/css/flaticon.css';
import '../src/assets/css/style.css';
import '../src/assets/css/responsive.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import HomePage from './pages/HomePage';
import ContactPage from './pages/Contact';
import AboutPage from './pages/About';
import Layout from './components/Layout';
import routes from './routes';
import Dashboard from './Dashboard/Dashboard';
import UserLayout from './Dashboard/UserLayout';
import Package from './Package/Package';
import PackageCourses from './Package/PackageCourses';
import Payment from './Package/Payment';
import Hold from './Dashboard/Hold';
import AdminLayout from './Admin/AdminLayout';
import Admin from './Admin/Admin';
import EmailConfirmation from './pages/EmailConfirmation';
import TermsConditions from './pages/TermsConditions';
import RefundPolicy from './pages/RefundPolicy';
import Agreement from './pages/Agreement';
import PrivacyPolicy from './pages/PrivacyPolicy';
import LoginPrivacyPolicy from './pages/LoginPrivacyPolicy';
import LoginTermsConditions from './pages/LoginTermsConditions';
import LoginAgreement from './pages/LoginAgreement';
import PackageDetailPage from './pages/PackageDetail';
import Payouts from './Dashboard/Payouts';
import UserProfile from './Dashboard/UserProfile';
import KycUserPannel from './components/KycUserPannel';
import Team from './Dashboard/Team';
import LoginUserAgreement from './components/LoginUserAgreement';
import ChangePassword from './components/ChangePassword';
import PackagePaymentRequest from './Admin/PackagePaymentRequest';
import KycDetail from './Admin/KycDetail';
import UserInformation from './Admin/UserInformation';
import WithDrawRequestApproval from './Admin/WithDrawRequestApproval';
import MyCourses from './Dashboard/Courses/MyCourses';
import Learning from './Dashboard/Courses/Learning';
import UpdateUserByAdmin from './Admin/UpdateUserByAdmin';
import ForgetPassword from './components/Forgetpassword';
import UpdateProfile from './Admin/UpdateProfile';
import AdminCourses from './Admin/AdminCourses';

function App() {
  React.useEffect(() => {
    // $('#myModal').modal('show');
  }, []);

  return (
    <>
      <Routes>
        <Route path={routes.home} element={<Layout><HomePage /></Layout>} />
        <Route path={routes.contactUs} element={<Layout><ContactPage /></Layout>} />
        <Route path={routes.aboutUs} element={<Layout><AboutPage /></Layout>} />
        <Route path={"/privacy-policy"} element={<Layout><PrivacyPolicy /></Layout>} />
        <Route path={"/refund-policy"} element={<Layout><RefundPolicy /></Layout>} />
        <Route path={"/terms"} element={<Layout><TermsConditions /></Layout>} />
        <Route path={"/agreement"} element={<Layout><Agreement /></Layout>} />
        <Route path={"/user-agreement"} element={<UserLayout><LoginUserAgreement /></UserLayout>} />
        <Route path={"/dashboard"} element={<UserLayout><Dashboard /></UserLayout>} />
        <Route path={"/package"} element={<UserLayout><Package /></UserLayout>} />
        <Route path={"/package-courses"} element={<UserLayout><PackageCourses /></UserLayout>} />
        <Route path={"/payment"} element={<UserLayout><Payment /></UserLayout>} />
        <Route path={"/hold"} element={<UserLayout><Hold /></UserLayout>} />
        <Route path={"/profile"} element={<UserLayout><UserProfile /></UserLayout>} />
        <Route path={"/kyc"} element={<UserLayout><KycUserPannel /></UserLayout>} />
        <Route path={"/emailConfirmation"} element={<EmailConfirmation />} />
        <Route path={"/privacypolicy"} element={<LoginPrivacyPolicy />} />
        <Route path={"/termsconditions"} element={<LoginTermsConditions />} />
        <Route path={"/affiliateagreement"} element={<LoginAgreement />} />
        <Route path={"/payouts"} element={<UserLayout><Payouts /></UserLayout>} />
        <Route path={"/team"} element={<UserLayout><Team /></UserLayout>} />
        <Route path={"/changepassword"} element={<UserLayout><ChangePassword /></UserLayout>} />
        <Route path={"/mycourses"} element={<UserLayout><MyCourses /></UserLayout>} />
        <Route path={"/learning"} element={<UserLayout><Learning /></UserLayout>} />
        <Route path={"/admin"} element={<AdminLayout><Admin /></AdminLayout>} />
        <Route path={"/packagepayment"} element={<AdminLayout><PackagePaymentRequest /></AdminLayout>} />
        <Route path={"/kycdetail"} element={<AdminLayout><KycDetail /></AdminLayout>} />
        <Route path={"/userdetail"} element={<AdminLayout><UserInformation /></AdminLayout>} />
        <Route path={"/withdrawrequests"} element={<AdminLayout><WithDrawRequestApproval /></AdminLayout>} />
        <Route path={"/admin"} element={<AdminLayout><Admin /></AdminLayout>} />
        <Route path={"/updateuser"} element={<AdminLayout><UpdateUserByAdmin /></AdminLayout>} />
        <Route path={"/adminchangepassword"} element={<AdminLayout><ChangePassword /></AdminLayout>} />
        <Route path={"/admincourses"} element={<AdminLayout><AdminCourses /></AdminLayout>} />
        <Route path={"/forgetpassword"} element={<ForgetPassword />} />
        <Route path={routes.courseDetail} element={<UserLayout><PackageDetailPage /></UserLayout>} />
        <Route path={"/adminprofile"} element={<AdminLayout><UpdateProfile /></AdminLayout>} />
      </Routes>
    </>
  );
}

export default App;
