import React, { useEffect, useRef, useState } from 'react';
import UserHeader from '../Dashboard/UserHeader';
import { putUserLogin } from '../handler/LoginAndSingupHandler';
import UserLoader from '../Dashboard/UserLoader';
import Pannel from '../Dashboard/Pannel';

interface LayoutProps {
  children: React.ReactNode;
}

const AdminLayout: React.FC<LayoutProps> = ({ children }) => {
  const hasRun = useRef(false);
  const [loader, setLoader] = useState(true);
  const userLoginOnRefresh = async () => {
    var response = await putUserLogin({
      "growniverseId": "",
      "password": ""
    });
    if (response.statusCode === 200) {
      var userData = response.data.loginResponse;
      if(!userData.profile.isAdmin)
      {
        window.location.href = "/dashboard";
      }
      localStorage.setItem("appkey", userData.appKey)
      localStorage.setItem("userProfile", JSON.stringify(userData.profile));
      localStorage.setItem("account", JSON.stringify(userData.account));
      setLoader(false);
    }
  }
  useEffect(() => {

    if (!hasRun.current) {
      const appKey = localStorage.getItem("appkey");
      if (appKey === null) {
        window.location.href = "/";
      }
      else {
        userLoginOnRefresh();
      }
      hasRun.current = true;
    }
  }, [hasRun])
  return (
    <>
      {loader && (
        <UserLoader />
      )}
      {!loader && (<div>
        <UserHeader />
        <Pannel />
        <main>{children}</main>
      </div>)}
    </>
  );
};

export default AdminLayout;
