import "../assets/css/package.css"
import scanner from "../Photos/Payment/Scanner.png"
import { Button, Input } from "@mui/material";
import "../components/WelcomeSection.css"
import { useEffect, useRef, useState } from "react";
import { paymentRequest } from "../handler/accountHandler";
import UserLoader from "../Dashboard/UserLoader";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import phonePay from '../Photos/Payment/PhonePay.png'
import gPay from '../Photos/Payment/GPay.png'
import paytm from '../Photos/Payment/Paytm.png'

const Payment = () => {
    const [file, setFile] = useState(null);
    const [transactionId, setTransactionId] = useState(null);
    const [loader, setLoader] = useState(false);
    const handleFileChange = (event: any) => {
        setFile(event.target.files[0]);
    };
    const handleTrChange = (event: any) => {
        setTransactionId(event.target.value);
    };

    const userProfileData = {
        appToken: "",
        userInformationId: 5,
        fullName: "Robin Sharma",
        sponsorGrowniverseId: "string",
        growniverseId: "G10025",
        address: "string",
        country: "India",
        state: null,
        pincode: 0,
        userIdentityGuid: "ef035009-7095-4a70-8882-63a86f062681-5bd0bcdb-a6d8-47a5-9c81-708f75c0b95f",
        emailID: "string",
        phoneNumber: "string",
        emailConfirmation: true,
        packageId: 0,
        packageName: "",
        isAdmin: false
    };
    const [packageDetailData, setPackageDetailPage] = useState({
        "packageId": 0,
        "packageName": "",
        "packageDescription": "Coming Soon",
        "postarLink": "",
        "packagePrice": 12000,
        "discountedPrice": 8500,
        "price": 6400
    })
    const [userProfile, setUserProfile] = useState(userProfileData);
    const runOnce = useRef(false);
    const formData2 = new FormData();
    const packagePaymentTransaction = async () => {
        setLoader(true);
        formData2.append("userInformationId", userProfile.userInformationId.toString());
        formData2.append("packageId", packageDetailData.packageId.toString());
        formData2.append("packageName", packageDetailData.packageName.toString());
        formData2.append("payment", packageDetailData.price.toString());
        if (!transactionId) {
            alert('Please select a file before submitting.');
            return;
        }
        formData2.append("transactionId", transactionId);
        if (!file) {
            alert('Please select a file before submitting.');
            return;
        }
        formData2.append('imageData', file);
        await paymentRequest(formData2);
        setLoader(true);
        window.location.href = "/hold"
    }
    const handlePaymentRedirect = (payment: string) => {
        var paymentLink = "";
        if (payment === "phonepay") {
            paymentLink = 'upi://pay?pa=growniverse@upi&pn=Growniverse&tn=' + packageDetailData.packageName + '&am=' + packageDetailData.price + '&cu=INR';
        } else if (payment === "gpay") {
            paymentLink = `intent://pay?pa=growniverse@upi&pn=Growniverse&tn=${packageDetailData.packageName}&am=${packageDetailData.price}&cu=INR#Intent;scheme=upi;package=com.google.android.apps.nbu.paisa.user;end`;
        } else if (payment === "paytm") {
            paymentLink = `intent://pay?pa=growniverse@upi&pn=Growniverse&tn=${packageDetailData.packageName}&am=${packageDetailData.price}&cu=INR#Intent;scheme=upi;package=net.one97.paytm;end`;
        }
        window.location.href = paymentLink;
        setPaymentInProgress(true);
        setTime(300);
    };
    useEffect(() => {
        if (runOnce.current === false) {
            var storedPackages = localStorage.getItem("packageDetail");
            var packagesData = JSON.parse(storedPackages ?? "");
            setPackageDetailPage(packagesData);
            const storedProfile = localStorage.getItem("userProfile");
            const userProfile = JSON.parse(storedProfile ?? "");
            setUserProfile(userProfile);
            runOnce.current = true;
        }
    }, [runOnce])
    const initialTime = 300; // Countdown starts from 60 seconds
    const [time, setTime] = useState(initialTime);

    useEffect(() => {
        if (time > 0) {
            const timerId = setTimeout(() => setTime(time - 1), 1000);
            return () => clearTimeout(timerId);
        }
    }, [time]);
    const [paymentInProgress, setPaymentInProgress] = useState(false);
    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        if (initialTime - time === initialTime) {
            setPaymentInProgress(false);
        }
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const progressPercentage = ((initialTime - time) / initialTime) * 100;
    return <>
        <section style={{ background: '#343434' }}>
            {
                loader && (
                    <UserLoader />
                )
            }
            {
                !loader && (
                    <>

                        {
                            userProfile.growniverseId === "G10029" ? (
                                <>
                                    <div className="headerBackground" style={{ padding: "20px" }}>
                                        {
                                            !paymentInProgress ?
                                                <div style={{ border: '1px solid #ccc', padding: '50px 20px 50px 20px', borderRadius: '10px', margin: '50px auto', backgroundColor: '#f5f5f5', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}>
                                                    <h2 style={{ textAlign: 'center', marginBottom: '20px', color: '#555' }}>Order Summary</h2>
                                                    <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'center' }}>
                                                        <li style={{ margin: '15px 0', padding: '15px', backgroundColor: '#e9e9e9', borderRadius: '8px' }}>
                                                            <div style={{ fontSize: '1.1em', fontWeight: 'bold', color: '#333' }}>{packageDetailData.packageName}</div>
                                                            <div style={{ marginTop: '10px', fontSize: '1em', color: '#888' }}>Original Price: <span style={{ textDecoration: 'line-through', color: '#999' }}>₹{packageDetailData.packagePrice}</span></div>
                                                            <div style={{ marginTop: '10px', fontSize: '1em', color: '#28a745' }}>Discounted Price: ₹{packageDetailData.price}</div>
                                                        </li>
                                                    </ul>
                                                    <div style={{ textAlign: 'right', marginTop: '25px', fontSize: '1.2em', fontWeight: 'bold', color: '#555' }}>
                                                        Total: <span style={{ color: "green" }}>₹{packageDetailData.price}</span>
                                                    </div>
                                                    <div className="row" style={{ marginTop: '20px', textAlign: 'center', display: "flex", justifyContent: "space-between" }}>
                                                        <Button onClick={() => { handlePaymentRedirect("gpay") }} className="col-3" style={{ color: '#fff', border: "1px solid #343434", borderRadius: "10px", padding: "10px" }}>
                                                            <img src={gPay} style={{ maxWidth: '100px', maxHeight: '100px', width: '100%', height: 'auto', margin: 'auto' }} />
                                                        </Button>
                                                        <Button onClick={() => { handlePaymentRedirect("phonepay") }} className="col-3" style={{ border: "1px solid #343434", borderRadius: "10px", color: '#fff', padding: "10px" }}>
                                                            <img src={phonePay} style={{ maxWidth: '100px', maxHeight: '100px', width: '100%', height: 'auto', margin: 'auto' }} />
                                                        </Button>
                                                        <Button onClick={() => { handlePaymentRedirect("paytm") }} className="col-3" style={{ border: "1px solid #343434", borderRadius: "10px", color: '#fff', padding: "10px" }}>
                                                            <img src={paytm} style={{ maxWidth: '100px', maxHeight: '100px', width: '100%', height: 'auto', margin: 'auto' }} />
                                                        </Button>
                                                    </div>
                                                </div> :
                                                <div style={{ textAlign: 'center', margin: '20px', background: "white", padding: "20px" }}>
                                                    <h1 style={{ color: "#ffab00" }}>{formatTime(time)}</h1>
                                                    <div style={{ textAlign: 'center', margin: '20px', background: 'white', padding: '20px', border: '1px solid #e0e0e0', borderRadius: '10px' }}>
                                                        <div style={{
                                                            width: '100%',
                                                            height: '30px',
                                                            backgroundColor: '#e0e0e0',
                                                            borderRadius: '5px',
                                                            overflow: 'hidden',
                                                        }}>
                                                            <div style={{
                                                                height: '100%',
                                                                backgroundColor: '#ffab00',
                                                                width: `${progressPercentage}%`,
                                                                transition: 'width 1s linear'
                                                            }}></div>
                                                        </div>
                                                    </div>

                                                    <span style={{ display: 'block', marginTop: '10px' }}>Do not Refresh</span>
                                                        <b>Please provide the Transaction ID and a screenshot of the transaction.</b>
                                                </div>

                                        }
                                        <div className="header" style={{ height: "auto", background: "white", padding: "10px", textAlign: "center", borderColor: "white", borderStyle: "inset", marginBottom: "10px" }}>
                                            <form onSubmit={packagePaymentTransaction}>
                                                <Input name='imageFileData' type="file" onChange={handleFileChange} required />
                                                <h4> <Input name='transactionId' style={{ color: "black" }} placeholder="Enter Transaction Id" onChange={handleTrChange} required /></h4>
                                                <Button variant="contained" style={{ background: "#fa9602" }} type="submit">
                                                    Submit
                                                </Button>
                                            </form>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div style={{ textAlign: "center", padding: "20px", borderColor: "white", borderStyle: "inset", marginBottom: "10px" }}>
                                        <img src={scanner} itemType="png" width={"300px"} height={"450px"} alt="Scanner Growniverse" />
                                        <h3 style={{ background: "#228B22", color: "#D3D3D3", marginTop: "10px" }}>
                                            UPI ID - growniverse@upi
                                        </h3>
                                        <h2 style={{ background: "#899ba1" }}>
                                            Bank Name - PUNJAB NATIONAL BANK
                                        </h2>
                                        <h2 style={{ background: "#899ba1" }}>
                                            Account Number - 7974002100000530
                                        </h2>
                                        <h2 style={{ background: "#899ba1" }}>
                                            IFSC CODE - PUNB0797400
                                        </h2>
                                        <div className="header" style={{ height: "100px", background: "white", padding: "10px" }}>
                                            <Input name='imageFileData' type="file" onChange={handleFileChange} required />
                                            <h4> <Input name='transactionId' style={{ color: "black" }} placeholder="Enter Transaction Id" onChange={handleTrChange} required /></h4>
                                            <Button variant="contained" style={{ background: "#fa9602" }} onClick={packagePaymentTransaction}>
                                                Submit
                                            </Button>
                                        </div><br /><br />
                                        <div style={{ background: "#343434" }}>
                                            <TableContainer style={{ background: "#DED8D8" }}>
                                                <h3 style={{ background: "#228B22", color: "#D3D3D3", marginTop: "10px", textAlign: "center" }}>Payment Detail</h3>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow >
                                                            <TableCell align="center">Package Name</TableCell>
                                                            <TableCell align="center">{packageDetailData.packageName}</TableCell>
                                                        </TableRow>
                                                        <TableRow >
                                                            <TableCell align="center">Price</TableCell>
                                                            <TableCell align="center" style={{ color: "green" }}>₹{packageDetailData.price}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </>
                )
            }
        </section>
    </>
};

export default Payment;