import { Alert, Input, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { passwordChange, putUserLogin, putUserSignOpt } from '../handler/LoginAndSingupHandler';
import axios from 'axios';
import UserLoader from '../Dashboard/UserLoader';

const ChangePassword = () => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);
    const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/;
    const [isValid, setIsValid] = useState(false);
    const [isMatch, setIsMatch] = useState(false);
    const [password, setPassowrd] = useState("");
    const [confirmPassword, setConfirmPassowrd] = useState("");
    const [singUpData, setSingUpData] = useState({});
    const [loader, setLoader] = useState(false);
    const [currentPasswordError, setCurrentPasswordError] = useState(false);
    const [currentPasswordValidated, setCurrentPasswordValidated] = useState(false);
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };

    const checkValidPassword = (event: any) => {
        const inputPassword = event.target.value;
        setPassowrd(event.target.value)
        setIsValid(passwordRegex.test(inputPassword)); // Validate password
        if (confirmPassword === inputPassword) {
            setIsMatch(true);
        }
        else {
            setIsMatch(false);
        }
        handleSignUpChange(event)
    };
    const checkConfirmPassword = (event: any) => {
        const inputPassword = event.target.value;
        setConfirmPassowrd(inputPassword);
        if (password === inputPassword) {
            setIsMatch(true);
        }
        else {
            setIsMatch(false);
        }
    };
    type State = {
        name: string;
        state_code: string;
    };


    const handleSignUpChange = (e: any) => {
        
        const { name, value } = e.target; // Get name and value from the input
        if (name === "Password") {
            setInvalidPassword(false);
        }
        setSingUpData((prev) => ({ ...prev, [name]: value })); // Update state dynamically
    };

    const [currentPassword, setCurrentPassword] = useState("");

    const checkCurrentpassword = async (event: any) => {
        var currentPassword = event.target.value;
        if (currentPassword.length > 0) {
            var userData = {
                "userId": userId,
                "passwordChange": 1,
                "currentPassword": currentPassword,
                "newPassword": ""
            };
            var response = await passwordChange(userData);
            if (response.statusCode === 200) {
                setCurrentPassword(currentPassword);
                setCurrentPasswordError(false);
                setCurrentPasswordValidated(true);
            }
            else if (response.statusCode === 203) {
                setCurrentPassword(currentPassword);
                setCurrentPasswordError(true);
            }
        }
    }
    const [userId, setUserId] = useState("");

    const currentpasswordChange = () => {
        setCurrentPasswordError(false);
        setCurrentPassword("");
    }
    const changePassword = async (event: any) => {
        event.preventDefault();
        setLoader(true);
        var userData = {
            "userId": userId,
            "passwordChange": 2,
            "currentPassword": currentPassword,
            "newPassword": password
        };
        var response = await passwordChange(userData);
        if (response.statusCode === 200) {
            setpasswordSuccesfull(true);
            setTimeout(() => {
                localStorage.clear();
                window.location.href = "/";
            }, 2000)
        }
        setLoader(false);
    }
    const [passwordSuccesFull, setpasswordSuccesfull] = useState(false);
    const runOnce = useRef(false);
    useEffect(() => {

        if (runOnce.current === false) {
            const storedProfile = localStorage.getItem("userProfile");
            const userProfile = JSON.parse(storedProfile ?? "");
            setUserId(userProfile.userIdentityGuid);
            runOnce.current = true;
        }
    }, [runOnce]);

    return (
        <section className='headerBackground'>
            <div className='space-ptb achievers-section' style={{ borderStyle: "inset", borderColor: "white", padding: "30px" }}>
                <div style={{ background: "#343434" }}>
                    <h3 style={{ textAlign: "center", color: "white" }}>Password Change</h3>
                </div>
                {
                    loader && (
                        <UserLoader />
                    )
                }
                {!passwordSuccesFull && (<>
                    {
                        !loader && (
                            <div style={{ background: "white", padding: "20px" }}>
                                <div className="tab-pane fade active show" id="login" role="tabpanel" aria-labelledby="login-tab">
                                    <form onSubmit={changePassword}>
                                        <div className='row'>
                                            <div className='col mb-3'>
                                                <label style={{ color: "black" }}>Current Password <span style={{ color: "red" }}>*</span></label>
                                                <input type="password" name='currentpassword' onChange={currentpasswordChange} className='form-control' onBlur={checkCurrentpassword} required />
                                                <span style={{ color: 'green', alignItems: 'center' }}></span>
                                                <h6 style={{ color: 'red', alignItems: 'center' }}>
                                                    {currentPassword.length > 0 && (
                                                        currentPasswordError ? <>
                                                            <Alert severity="error" variant="outlined">
                                                                Invalid Current Password
                                                            </Alert>
                                                        </> :
                                                            <Alert severity="success" variant="outlined">
                                                                Verified
                                                            </Alert>
                                                    )}
                                                </h6>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col mb-3'>
                                                <label style={{ color: "black" }}>New Password</label>
                                                <div className="input-wrapper position-relative password-field">
                                                    <input
                                                        type={passwordVisible ? "text" : "password"}
                                                        placeholder="Password"
                                                        className='form-control'
                                                        onChange={checkValidPassword}
                                                        name='password'
                                                        disabled={!currentPasswordValidated}
                                                        required />
                                                    <button className='eye-btn' type="button" onClick={togglePasswordVisibility}>
                                                        <i className="eye-icon">👁️</i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='col mb-3'>
                                                <label style={{ color: "black" }}>Confirm New Password</label>
                                                <div className="input-wrapper position-relative password-field">
                                                    <input
                                                        type={confirmPasswordVisible ? "text" : "password"}
                                                        placeholder="Confirm Password" className='form-control'
                                                        disabled={!isValid}
                                                        onChange={checkConfirmPassword}
                                                        required />
                                                    <button
                                                        type="button"
                                                        className='eye-btn'
                                                        onClick={toggleConfirmPasswordVisibility}
                                                    >
                                                        <i className="eye-icon">👁️</i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div style={{ background: "white" }}>
                                                {password.length > 0 && (
                                                    isValid ? (
                                                        <p style={{ color: "green" }}>Password is Valid.</p>
                                                    ) : (
                                                        <p style={{ color: "red" }}>Password must include uppercase, lowercase, number, special character, no spaces, and be 8-16 characters.</p>
                                                    )
                                                )}
                                                {confirmPassword.length > 0 && (
                                                    isMatch ? (
                                                        <p style={{ color: "green" }}>Confirm Password Is Valid.</p>
                                                    ) : (
                                                        <p style={{ color: "red" }}>Password Confirm Password.</p>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col text-center'>
                                                <button type="submit" className="btn btn-primary">Change Password</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )
                    }
                </>)}
                {
                    passwordSuccesFull && (<>
                        <Alert severity="success" variant="outlined" style={{background:"white"}}>
                            Password Successfully Changed
                        </Alert>
                    </>)
                }
            </div>
        </section>
    );
};

export default ChangePassword;
