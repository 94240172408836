import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import "../components/WelcomeSection.css"
import GrowniverseLogo from "../Photos/Logo/growniverse-logo-full-bordered.svg";
import GrowniverseLogoFull from "../Photos/Logo/growniverse-logo-full.svg";
import { Link, useLocation } from 'react-router-dom';
import "./Design/Dashboard.css"
const UserHeader = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/dashboard';
  const isActive = (path: string) => location.pathname === path ? 'active' : '';
  const [userName, setUserName] = useState("");
  const [profileImage, setProfileImage] = useState("");
  useEffect(() => {
    const appKey = localStorage.getItem("appkey");
    if (appKey && appKey !== "NeedVerification") {
      const storedProfile = localStorage.getItem("userProfile");
      const userProfile = JSON.parse(storedProfile ?? "");
      setUserName(userProfile.fullName);
      setProfileImage(userProfile.profileImage);
    }
    else {
      window.location.href = "/";
    }
  });
  return (
    <>
      <section className='row headerBackground' style={{ display: "flex", justifyContent: "space-between", height: "auto" }}>
        <div className='col-2' style={{ display: "flex", paddingLeft: "20px", alignContent: "center", textAlign: "left" }}>
          <img src={GrowniverseLogo} width={"80px"} height={"100%"} alt='Growniverse' />
        </div>
        <div className='col-5' style={{ background: "", alignContent: "center", textAlign: "center" }}>
          <img src={GrowniverseLogoFull} width={"120%"} alt='GrowniverseLogo' />
        </div>
        <div className='col-3 row'
          style={{
            textAlign: "right",
            paddingRight: "40px",
            justifyContent: "end",
            alignContent: "center",
          }}>
          {
            !profileImage && (<>
              <Avatar className='col-4' name={userName} size="50" round={true} />
            </>)
          }
          {
            profileImage &&(<>
            <img src={`data:image/jpeg;base64,${profileImage}`} alt="Decoded from Base64" style={{ width: "80px",height:"40px",borderRadius:"100%" }} />
            </>)
          }
        </div>
        <div className="container-fluid position-relative px-0">
        </div>
      </section>

    </>
  );
};

export default UserHeader;
