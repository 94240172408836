import Avatar from "react-avatar";
import "./Design/Dashboard.css"
import "../components/WelcomeSection.css";

import { useEffect, useState } from "react";
interface InnerBannerProps {
    userProfile: {
        fullName: string,
        growniverseId: string,
        packageName: string,
        isAdmin: boolean,
        profileImage: string
    };
}
const DashboardProfile: React.FC<InnerBannerProps> = ({ userProfile }) => {

    return <>
        <section style={{ background: "#343434", width: "100%" }}>
            <div className="container dashboardProfile">
                <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                    {
                        userProfile.profileImage && (
                            <img src={`data:image/jpeg;base64,${userProfile.profileImage}`} alt="Decoded from Base64" style={{ width: "100px", height: "100px", borderRadius: "60px", border: "2.5px solid black" }} />
                        )
                    }
                    {
                        !userProfile.profileImage && (
                            <Avatar name={userProfile.fullName} size="100" round={true} />
                        )
                    }

                </div>
                <div style={{ textAlign: "center" }}>

                    <div style={{ color: "black",border:"2px solid black", background: "#899ba1", borderRadius: "30px", width: "300px", display: "inline-grid" }}>
                        <h2>{userProfile.fullName}</h2>
                        <h3>{userProfile.growniverseId}</h3>
                        {
                            userProfile.isAdmin && (
                                <>
                                    <h3 style={{ color: "#Daa425", textShadow: "0 0 3px #FF0000, 0 0 5px #0000FF" }}>Growniverse Founder</h3>
                                </>
                            )
                        }
                        <h3 style={{ color: "#Daa425", textShadow: "0 0 3px #FF0000, 0 0 5px #0000FF" }}>{userProfile.packageName}</h3>
                    </div>
                </div>
            </div>


        </section>
    </>
};

export default DashboardProfile;