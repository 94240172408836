import React, { useEffect, useRef, useState } from 'react';
import Footer from '../components/Footer';
import UserHeader from './UserHeader';
import Pannel from './Pannel';
import UserLoader from './UserLoader';
import { putUserLogin } from '../handler/LoginAndSingupHandler';
import { Table, TableCell, TableHead } from '@mui/material';
import banner from "../Photos/Banner/Banner.png"

interface LayoutProps {
  children: React.ReactNode;
}

const UserLayout: React.FC<LayoutProps> = ({ children }) => {
  const hasRun = useRef(false);
  const [loader, setLoader] = useState(true);
  const userLoginOnRefresh = async () => {
    var response = await putUserLogin({
      "growniverseId": "",
      "password": ""
    });
    if (response.statusCode === 200) {
      var userData = response.data.loginResponse;
      const pathName = window.location.pathname;
      if (pathName !== "/package" && pathName !== "/hold" && pathName !== "/packageDetail" && pathName !== "/payment") {
        if (userData.profile.status === 2) {
          window.location.href = "/package"
        }
        else if (userData.profile.status === 3) {
          window.location.href = "/hold"
        }
      }
      else if (pathName === "/payment") {
        if (userData.profile.status >= 4) {
          window.location.href = "/dashboard"
        }
      }
      else if (pathName === "/hold") {
        if (userData.profile.status < 3) {
          window.location.href = "/package"
        }
      }
      localStorage.setItem("appkey", userData.appKey)
      localStorage.setItem("userProfile", JSON.stringify(userData.profile));
      localStorage.setItem("account", JSON.stringify(userData.account));
      if (userData.profile.packageId === 0) {
        localStorage.setItem("packages", JSON.stringify(userData.packages));
      }
      setLoader(false);
      if (userData.profile.isAdmin) {
        window.location.href = "/packagepayment";
      }
    }
  }
  useEffect(() => {

    if (!hasRun.current) {
      const appKey = localStorage.getItem("appkey");
      if (appKey === null) {
        window.location.href = "/";
      }
      else {
        userLoginOnRefresh();
      }
      hasRun.current = true;
    }
  }, [hasRun])
  const [showBanner, setShowBanner] = useState(true);

  return (
    <>
      <section className={showBanner ? '' : 'dashboardProfileForStatic'} onClick={() => { setShowBanner(false) }}>
        {loader && <UserLoader />}
        {!loader && (
          <>
            {(window.location.pathname === "/dashboard" && showBanner) && (<>
              <>
                <section>
                  <div style={{ background: "rgba(0, 0, 0, .5)", position: "fixed", height: "100%", width: "100%", left: "0", top: "0", zIndex: 1000 }}>
                    <img src={banner} width="100%" height="auto" style={{ padding: "50px 20px 20px 20px" }} />
                  </div>
                </section>
              </>
            </>)}
            <div>
              <div style={showBanner ? { color: "white" } : { position: "fixed", top: 0, left: 0, width: "100%", zIndex: 1000 }}>
                <UserHeader />
                <Pannel />
              </div>
              <div style={showBanner ? {} : { marginTop: "calc(100px)" }} className={showBanner ? '' : 'dashboardProfileForStatic'}>
                <main>
                  {children}
                </main>
                <Footer />
              </div>
            </div>
          </>
        )}
      </section>
    </>
  );
};

export default UserLayout;
